import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import get from 'lodash.get';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import GeoEditorSideBarActions from 'components/GeoEditorSideBarActions';
import Table from 'components/DetailsTable';
import SideBar from 'common/BootstrapSNCF/SideBarSNCF';
import ToolsBar, { JSON_KEYS } from 'components/Map/SideBar/SideBarTools';
import SideBarInfosCustomTitle from 'components/Map/SideBar/SideBarInfosCustom';
import ModificationButtons from 'components/Map/SideBar/ModificationButtons';
import { findPathsToKey, validatePk } from 'utils/helpers';
import * as mapActions from 'reducers/map';
import {
  NEW_GROUPEMENT_CATENAIRE,
  NEW_OBJECT_REF, NEW_PERIMETRE, NEW_POSTE, NEW_SIGNAL_DESTINATION, NEW_SIGNAL_ORIGIN,
} from 'common/Map/const';

function SideBarWrapper({
  displaySideBar, onValidate, onDeleteObject, updateModal,
  modalId, onSideBarClose, sideBarRef, onRestoreLRS,
}) {
  const dispatch = useDispatch();
  const map = useSelector((state) => state.map);
  const { t } = useTranslation();

  const validateObjects = () => {
    let isPkValid = true;
    const pathsToPk = findPathsToKey(map.popupContent, 'pk_sncf');
    if (pathsToPk && pathsToPk.length !== 0) {
      pathsToPk.forEach((path) => {
        isPkValid = isPkValid && validatePk(get(map.popupContent, path));
      });
    }

    let newModalHeaderTitle;
    let newModalBody;
    let newModalFooter;

    if (!isPkValid) {
      newModalHeaderTitle = t('Map.popupDetails.errorModal.header');
      newModalBody = (
        <div>
          {t('Map.popupDetails.errorModal.body')}
        </div>
      );
      newModalFooter = (
        <div className="d-flex align-items-end justify-content-end" style={{ width: '420px' }}>
          <div className="btn-group dropdown">
            <button type="button" className="btn btn-sm btn-secondary mr-2" data-dismiss="modal">
              <span>{t('Map.popupDetails.discartButton')}</span>
            </button>
          </div>
        </div>
      );
    } else {
      newModalHeaderTitle = t('Map.popupDetails.validateNotice');
      newModalBody = (<></>);
      newModalFooter = (
        <ModificationButtons
          buttonTitle={t('Map.popupDetails.validateButton')}
          color="btn-primary"
          onClick={onValidate}
        />
      );
    }

    updateModal(
      newModalHeaderTitle,
      newModalBody,
      newModalFooter,
    );
  };

  const switchToLRS = () => {
    const newModalHeaderTitle = t('Map.popupDetails.restoreLRS.title');
    const newModalBody = (<p>{t('Map.popupDetails.restoreLRS.body')}</p>);
    const newModalFooter = (
      <ModificationButtons
        buttonTitle={t('Map.popupDetails.restoreLRS.button')}
        color="btn-primary"
        onClick={onRestoreLRS}
      />
    );

    updateModal(
      newModalHeaderTitle,
      newModalBody,
      newModalFooter,
    );
  };

  const deleteObjects = () => {
    const newModalHeaderTitle = t('Map.popupDetails.deleteNotice.object');
    const newModalBody = (<></>);
    const newModalFooter = (
      <ModificationButtons
        buttonTitle={t('Map.popupDetails.deleteButton')}
        color="btn-danger"
        onClick={onDeleteObject}
      />
    );

    updateModal(
      newModalHeaderTitle,
      newModalBody,
      newModalFooter,
    );
  };

  const addJsonField = (fieldName) => {
    let fieldToAdd = {};
    const jsonField = [];
    let newPopupContent = { ...map.popupContent };
    const fieldKey = fieldName;

    if (map.popupContent[fieldName]) {
      map.popupContent[fieldName].forEach((field) => jsonField.push(field));
    }

    if (fieldName === JSON_KEYS.itineraires_impactes) {
      jsonField.push(NEW_OBJECT_REF);
    } else if (fieldName === JSON_KEYS.cles_protections) {
      jsonField.push('');
    } else if (fieldName === JSON_KEYS.perimetres_associes) {
      jsonField.push(NEW_PERIMETRE);
    } else if (fieldName === JSON_KEYS.perimetres_responsables) {
      jsonField.push(NEW_PERIMETRE);
    } else if (fieldName === JSON_KEYS.groupements_catenaires) {
      jsonField.push(NEW_GROUPEMENT_CATENAIRE);
    } else if (fieldName === JSON_KEYS.sels && map.popupContent[fieldName].length === 0) {
      jsonField.push(NEW_GROUPEMENT_CATENAIRE);
    } else if (map.popupContent[fieldName] && map.popupContent[fieldName].length > 0) {
      Object.entries(map.popupContent[fieldName][0]).forEach(([key, value]) => {
        if (Array.isArray(value)) {
          const newArr = [];
          value.forEach((v) => {
            let subField = {};
            Object.keys(v).forEach((k) => {
              subField = { ...subField, [k]: '' };
            });
            newArr.push(subField);
          });
          fieldToAdd = { ...fieldToAdd, [key]: newArr };
        } else {
          fieldToAdd = { ...fieldToAdd, [key]: '' };
        }
      });

      jsonField.push(fieldToAdd);
    }
    // update popup content
    if (fieldKey === JSON_KEYS.signal_origin) {
      newPopupContent = { ...newPopupContent, ...NEW_SIGNAL_ORIGIN };
    } else if (fieldKey === JSON_KEYS.signal_destination) {
      newPopupContent = { ...newPopupContent, ...NEW_SIGNAL_DESTINATION };
    } else if (fieldKey === JSON_KEYS.poste) {
      newPopupContent = { ...newPopupContent, ...NEW_POSTE };
    } else if (fieldKey === JSON_KEYS.tables_responsables) {
      newPopupContent = { ...newPopupContent, tables_responsables: '' };
    } else if (fieldKey === JSON_KEYS.tables_associees) {
      newPopupContent = { ...newPopupContent, tables_associees: '' };
    } else if (fieldKey === JSON_KEYS.is_mgpt) {
      newPopupContent = { ...newPopupContent, is_mgpt: 0 };
    } else {
      newPopupContent = { ...newPopupContent, [fieldKey]: jsonField };
    }
    dispatch(mapActions.updatePopupContent(newPopupContent));
  };

  if (displaySideBar) {
    const content = map.sidebarTab === '#geoEditor'
      ? <GeoEditorSideBarActions />
      : <Table />;
    return (
      <SideBar
        title={<SideBarInfosCustomTitle {...map.popupContent} />}
        toolBar={(
          <ToolsBar
            validateObjects={validateObjects}
            deleteObjects={deleteObjects}
            switchToLRS={switchToLRS}
            modalId={modalId}
          />
        )}
        content={content}
        addTools={<ToolsBar addRows={addJsonField} modalId={modalId} />}
        onClose={onSideBarClose}
        ref={sideBarRef}
      />
    );
  }
  return null;
}

SideBarWrapper.propTypes = {
  displaySideBar: PropTypes.bool.isRequired,
  onValidate: PropTypes.func.isRequired,
  onDeleteObject: PropTypes.func.isRequired,
  modalId: PropTypes.string.isRequired,
  onSideBarClose: PropTypes.func.isRequired,
  sideBarRef: PropTypes.object.isRequired,
  updateModal: PropTypes.func.isRequired,
  onRestoreLRS: PropTypes.func.isRequired,
};

export default SideBarWrapper;
