import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  DETAILS_TABLE_FIELDS, ITEMS_TYPES, MAP_MODES,
} from 'common/Map/const';
import { GoPlus } from 'react-icons/go';
import { useTranslation } from 'react-i18next';
import { updateFilterValue } from 'reducers/map';
import { useDispatch, useSelector } from 'react-redux';
import nextId from 'react-id-generator';
import InputSNCF from './InputSNCF';

const SelectSNCF = ({
  id, title, name, options, selectedValue, onChange, labelKey, selectStyle, mode, additional, multiple, multiSelectTitle, withFilter,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const {
    filterValue, filteredPostesMetier, filteredItineraires,
    filteredTablesAssociees, filteredTablesResponsables, perimetresAssocies, perimetresResponsables,
  } = useSelector((state) => state.map);
  const [isActive, setIsActive] = useState(false);
  const [additionalOptions, setAdditionalOptions] = useState(options);
  const [selectedOption, setSelectedOption] = useState('');
  const [newValue, setNewValue] = useState('');
  const [multiSelectPostes, setMultiSelectPostes] = useState([]);
  const [multiSelectTables, setMultiSelectTables] = useState([]);
  const [multiSelectItineraires, setMultiSelectItineraires] = useState([]);

  useEffect(() => {
    if (selectedValue?.length !== 0 && multiple) {
      const checkedMultiSelect = [];
      selectedValue.forEach((value) => {
        checkedMultiSelect.push({ option: value, checked: 'active' });
      });

      if (multiSelectTitle === t('Map.popupDetails.allPostes')) {
        setMultiSelectPostes(checkedMultiSelect);
      } else {
        setMultiSelectTables(checkedMultiSelect);
      }
    }
  }, [selectedValue]);

  useEffect(() => {
    if (additionalOptions !== options) {
      setAdditionalOptions(options);
    }
  }, [options]);

  const getItemsType = () => {
    if (multiSelectTitle === t('Map.popupDetails.allItineraires')) return ITEMS_TYPES.itineraires;
    if (multiSelectTitle === t('Map.popupDetails.allTablesAssociees') && perimetresAssocies.length !== 0) return ITEMS_TYPES.tablesAssociees;
    if (multiSelectTitle === t('Map.popupDetails.allTablesResponsables') && perimetresResponsables.length !== 0) return ITEMS_TYPES.tablesResponsables;
    return ITEMS_TYPES.postesMetier;
  };

  const search = () => (
    <InputSNCF
      id={`${nextId()}-search`}
      value={filterValue}
      onChange={(e) => dispatch(updateFilterValue(e.target.value, getItemsType()))}
      onClear={() => dispatch(updateFilterValue('', getItemsType()))}
      placeholder={t('Logs.filters.searchPlaceholder')}
      noMargin
      clearButton
      sm
    />
  );

  const renderOptions = (optionsList) => optionsList.map((option) => {
    if (typeof option === 'string') {
      return (
        <option
          key={option}
          value={option}
        >
          {option}
        </option>
      );
    }
    return (
      <option
        key={option.id || option.key || option.user_id}
        value={JSON.stringify(option)}
      >
        {option.last_name === undefined && `${option.RA_libelle_gare !== undefined ? `${option.RA_libelle_gare} -` : ''} ${option[labelKey] !== '' ? option[labelKey] : ''}`}
        {option.last_name !== undefined ? `${option.last_name} ${option.first_name}` : ''}
      </option>
    );
  });

  const onOptionChange = (e) => {
    setSelectedOption(e.currentTarget.title);
    setIsActive(!isActive);
    onChange(e);
  };

  const renderAddOptions = (optionsList) => optionsList.map((option, index) => {
    const label = `${option.RA_libelle_gare !== undefined ? `${option.RA_libelle_gare} -` : ''} ${option[labelKey] !== '' ? option[labelKey] : ''}` || `${option.lastName} ${option.firstName}`;
    if (typeof option === 'string') {
      return (
        <span className="select-menu-item" role="listitem"><button type="button" data-role="value" data-target={index} onClick={(e) => onOptionChange(e)}>{option}</button></span>
      );
    }
    return (
      <span className="select-menu-item" role="listitem" key={option.id || option.key}>
        <button type="button" data-role="value" data-target={index} title={label} onClick={(e) => onOptionChange(e)}>{label}</button>
      </span>

    );
  });

  const updateOptions = () => {
    const newOptions = additionalOptions;
    newOptions.push({ key: additionalOptions.length + 1, label: newValue });
    setAdditionalOptions(newOptions);
    setSelectedOption(newValue);
  };

  const renderSelectWithInput = () => {
    const optionsList = additionalOptions.length !== options.lenth ? additionalOptions : options;
    return (
      <>
        <div className={`select-improved ${isActive ? 'active' : ''}`} data-component="select-exclusive">
          <div className="select-control" style={{ width: '15rem' }}>
            <div className="input-group" data-role="select-toggle">
              <p className="text-monospace form-control form-control-sm select-add-placeholder" data-role="placeholder" data-selected-prefix="Sélection actuelle">{selectedOption === '' ? selectedValue : selectedOption}</p>
              <select
                id={id}
                name={name}
                className={`${selectStyle} sr-only`}
                multiple
              >
                {renderOptions(optionsList)}
              </select>
              <div className="input-group-append input-group-last">
                <button className={`btn btn-sm btn-primary btn-only-icon select-add-append ${isActive ? 'active' : ''}`} data-role="btn" type="button" aria-expanded={isActive} aria-controls="selecttoggle" onClick={() => setIsActive(!isActive)}>
                  <i className="icons-arrow-down icons-size-x5" aria-hidden="true" />
                </button>
              </div>
            </div>
            <div className="select-menu" id="-selecttoggle">
              <div className="d-flex flex-column">
                <div className="flex-fluid overflow-y" role="list" data-role="menu">
                  {renderAddOptions(optionsList)}
                </div>
                <div className="d-flex pt-4 flex-column flex-sm-row" data-role="add">
                  <div className="sideBarTools-container">
                    <input id={`${nextId()}-addagent`} type="text" className="text-monospace form-control form-control-sm" style={{ fontSize: 'x-small' }} data-role="add-input" placeholder="Ajouter table" onChange={(e) => setNewValue(e.currentTarget.value)} />
                  </div>
                  <div className="pt-2 pt-sm-0 pl-sm-2">
                    <button type="button" className="btn btn-primary btn-sm-sidebar btn-block d-sm-inline-block" data-role="add-btn" onClick={updateOptions}>
                      <GoPlus
                        size="1em"
                      />
                    </button>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  const multiSelect = (e, option = undefined) => {
    let selectedValues = [];
    const checkedMultiSelect = [];

    if (multiSelectTitle === t('Map.popupDetails.allPostes')) {
      const comparedValue = e.currentTarget.textContent.trim();

      multiSelectPostes.forEach((value) => selectedValues.push(value.option));
      const filteredOption = selectedValues.length !== 0 ? selectedValues.filter((value) => `${value[0].RA_libelle}` === comparedValue) : '';
      if (filteredOption.length !== 0) {
        selectedValues = selectedValues.filter((value) => `${value[0].RA_libelle}` !== comparedValue);
      } else {
        selectedValues.push(options.filter((op) => op.RA_libelle === comparedValue));
      }
    } else if (multiSelectTitle === t('Map.popupDetails.allTablesAssociees') || multiSelectTitle === t('Map.popupDetails.allTablesResponsables')) {
      const comparedValue = option?.table || e.currentTarget.textContent.trim();

      multiSelectTables.forEach((value) => selectedValues.push(value.option));
      const filteredOption = selectedValues.length !== 0 ? selectedValues.filter((value) => value.table === comparedValue && value.poste_libelle === option.poste_libelle) : '';
      if (filteredOption.length !== 0) {
        selectedValues = selectedValues.filter((value) => `${value.poste_libelle}/${value.table}` !== `${option.poste_libelle}/${option.table}`);
      } else {
        selectedValues.push(options.filter((op) => op.table === comparedValue && op.poste_libelle === option.poste_libelle)[0]);
      }
    } else {
      multiSelectItineraires.forEach((value) => selectedValues.push(value.option));
      const filteredOption = selectedValues.length !== 0 ? selectedValues.filter((value) => value[0].RA_libelle === e.currentTarget.textContent.trim()) : '';
      if (filteredOption.length !== 0) {
        selectedValues = selectedValues.filter((value) => value[0].RA_libelle !== e.currentTarget.textContent.trim());
      } else {
        selectedValues.push(options.filter((op) => op.RA_libelle === e.currentTarget.textContent.trim()));
      }
    }
    selectedValues.forEach((value) => {
      checkedMultiSelect.push({ option: value, checked: 'active' });
    });

    if (multiSelectTitle === t('Map.popupDetails.allPostes')) {
      setMultiSelectPostes(checkedMultiSelect);
    } else if (multiSelectTitle === t('Map.popupDetails.allTablesAssociees') || multiSelectTitle === t('Map.popupDetails.allTablesResponsables')) {
      setMultiSelectTables(checkedMultiSelect);
    } else {
      setMultiSelectItineraires(checkedMultiSelect);
    }
    onChange(selectedValues);
  };

  const getLabel = (option) => (option !== undefined && option !== null
    ? `${option.RA_libelle_gare !== undefined && option.RA_libelle_gare !== null && Object.keys(option).includes('tables') ? `${option.RA_libelle_gare} -` : ''} ${option[labelKey] !== '' ? option[labelKey] : ''}` || `${option.lastName} ${option.firstName}`
    : '');

  const renderCheckBoxOptions = (optionsList) => {
    const nbOfElements = [DETAILS_TABLE_FIELDS.perimetres_associes, DETAILS_TABLE_FIELDS.tables_associees].some((f) => id.includes(f)) ? 100 : 20;
    return optionsList.slice(0, nbOfElements).map((option) => {
      const label = getLabel(option);
      let active;
      if (multiSelectTitle === t('Map.popupDetails.allPostes')) {
        active = multiSelectPostes.filter((value) => value.option[0].OP_id === option.OP_id) ? multiSelectPostes.filter((value) => value.option[0].OP_id === option.OP_id)[0]?.checked : '';
      } else if (multiSelectTitle === t('Map.popupDetails.allTablesAssociees') || multiSelectTitle === t('Map.popupDetails.allTablesResponsables')) {
        active = multiSelectTables.filter((value) => value.option.table === option.table && value.option.poste_id === option.poste_id)
          ? multiSelectTables.filter((value) => value.option.table === option.table && value.option.poste_id === option.poste_id)[0]?.checked : '';
      } else {
        active = multiSelectItineraires.filter((value) => value.option[0].OP_id === option.OP_id) ? multiSelectItineraires.filter((value) => value.option[0].OP_id === option.OP_id)[0]?.checked : '';
      }

      if (typeof option === 'string') {
        return (
          <div className="select-menu-item" role="listitem">
            <div className="custom-control custom-checkbox">
              <button type="button" data-role="value" data-target="0" role="checkbox" aria-checked="false" className={`custom-control-label w-100 text-left font-weight-medium ${active}`} onClick={(e) => multiSelect(e)}>
                {option}
              </button>
            </div>
          </div>
        );
      }
      if (option.poste_id) {
        return (
          <div className="select-menu-item" role="listitem">
            <div className="custom-control custom-checkbox">
              <button type="button" data-role="value" data-target="0" role="checkbox" aria-checked="false" className={`custom-control-label w-100 text-left font-weight-medium ${active}`} onClick={(e) => multiSelect(e, option)}>
                {`${option.poste_libelle}/${option.table}`}
              </button>
            </div>
          </div>
        );
      }
      return (
        <div className="select-menu-item" role="listitem" key={option.id || option.key}>
          <div className="custom-control custom-checkbox">
            <button type="button" role="checkbox" aria-checked="false" className={`custom-control-label w-100 text-left font-weight-medium ${active}`} onClick={(e) => multiSelect(e)}>
              {label}
            </button>
          </div>
        </div>
      );
    });
  };

  const getOptionsList = () => {
    if (withFilter || multiSelectTitle === t('Map.popupDetails.allPostes')) return filteredPostesMetier;
    if (multiSelectTitle === t('Map.popupDetails.allItineraires')) return filteredItineraires;
    if (multiSelectTitle === t('Map.popupDetails.allTablesAssociees')) return filteredTablesAssociees;
    if (multiSelectTitle === t('Map.popupDetails.allTablesResponsables')) return filteredTablesResponsables;
    return options;
  };

  const renderMultiSelect = () => (
    <div className={`select-improved ${isActive ? 'active' : ''}`} data-component="select-multiple">
      <div className="select-control" style={{ width: '15rem' }}>
        <div className="input-group" data-role="select-toggle">
          <div className="form-control form-control-sm" style={{ border: 0, height: '1.375rem' }}>
            <div className="custom-control">
              {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
              <label data-role="placeholder" className={`custom-control-label text-monospace form-control-sm ${multiSelectTables.length !== 0 || multiSelectPostes.length !== 0 || multiSelectItineraires.length !== 0 ? 'indeterminate' : ''}`}>{multiSelectTitle}</label>
            </div>
          </div>
          <select
            id={id}
            name={name}
            defaultValue={selectedValue}
            className={selectStyle}
            multiple
          >
            {renderOptions(options)}
          </select>
          <div className="input-group-append input-group-last">
            <button className={`btn btn-sm btn-primary btn-only-icon select-add-append ${isActive ? 'active' : ''}`} data-role="btn" type="button" aria-expanded={isActive} aria-controls="multiselecttoggle" onClick={() => setIsActive(!isActive)}>
              <i className="icons-arrow-down icons-size-x5" aria-hidden="true" />
            </button>
          </div>
        </div>
        <div id="multiselecttoggle" className="select-menu" data-role="menu">
          <div className="select-group" data-role="group" data-id="0" role="list">
            <div className="cell-inner small-cell-sidebar mb-4">
              {search()}
            </div>
            {renderCheckBoxOptions(getOptionsList())}
          </div>
        </div>
      </div>
    </div>
  );

  const renderSelectWithSearch = () => (
    <div className={`select-improved ${isActive ? 'active' : ''}`} data-component="select-exclusive">
      <div className="select-control" style={{ width: '15rem' }}>
        <div className="input-group" data-role="select-toggle">
          <div className="form-control form-control-sm is-placeholder d-flex align-items-center" data-role="placeholder">{getLabel(selectedValue)}</div>
          <select
            id={id}
            name={name}
            value={selectedValue}
            className={selectStyle}
          >
            {renderOptions(getOptionsList())}
          </select>
          <div className="input-group-append input-group-last">
            <button className={`btn btn-sm btn-primary btn-only-icon select-add-append ${isActive ? 'active' : ''}`} data-role="btn" type="button" aria-expanded={isActive} onClick={() => setIsActive(!isActive)}>
              <i className="icons-arrow-down icons-size-x5" aria-hidden="true" />
            </button>
          </div>
        </div>
        <div className="select-menu" data-role="menu">
          <div className="select-group" data-role="group" data-id="0" role="list">
            <div className="cell-inner small-cell-sidebar mb-4">
              {search()}
            </div>
            {renderAddOptions(getOptionsList())}
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <>
      <label htmlFor={id}>{title}</label>
      {!additional && !multiple && !withFilter && (mode === MAP_MODES.modification
        ? (
          <select
            id={id}
            name={name}
            value={typeof selectedValue === 'string' ? selectedValue : JSON.stringify(selectedValue)}
            onChange={onChange}
            className={selectStyle}
          >
            {renderOptions(options)}
          </select>
        )
        : (
          <select
            id={id}
            name={name}
            defaultValue={typeof selectedValue === 'string' ? selectedValue : JSON.stringify(selectedValue)}
            onChange={onChange}
            className={selectStyle}
          >
            {renderOptions(options)}
          </select>
        ))}
      {additional && renderSelectWithInput()}
      {multiple && renderMultiSelect()}
      {withFilter && renderSelectWithSearch()}
    </>
  );
};

SelectSNCF.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string,
  name: PropTypes.string,
  options: PropTypes.array.isRequired,
  selectedValue: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string,
  ]).isRequired,
  onChange: PropTypes.func.isRequired,
  labelKey: PropTypes.string,
  selectStyle: PropTypes.string,
  mode: PropTypes.string,
  additional: PropTypes.bool,
  multiple: PropTypes.bool,
  multiSelectTitle: PropTypes.string,
  withFilter: PropTypes.bool,
};

SelectSNCF.defaultProps = {
  title: '',
  labelKey: 'name',
  selectStyle: '',
  name: '',
  mode: '',
  additional: false,
  multiple: false,
  multiSelectTitle: '',
  withFilter: false,
};

export default SelectSNCF;
