/* eslint-disable default-case */
import produce from 'immer';
import {
  transformRequest, gpsRound, filterBySearch, updateChildElementSelect,
} from 'utils/helpers';
import mapStyleEmptyJSON from 'assets/mapstyles/style_osrd_empty.json';
import history from 'appHistory';
import {
  MAP_URL, MAP_MODES, MAP_TRACK_SOURCES, ITEMS_TYPES, DEFAULT_VIEWPORT,
} from 'common/Map/const';
import { post, get } from 'common/requests';
import {
  set, unset, pullAt, dropRight, last, get as getObj,
} from 'lodash';
import { MIDI_OBJECTS_KEYS } from 'common/Map/Consts/MidiObjects';

// Action Types
export const UPDATE_VIEWPORT = 'map/UPDATE_VIEWPORT';
export const UPDATE_REF = 'map/UPDATE_REF';
export const UPDATE_TRANSFORM_REQUEST = 'map/UPDATE_TRANSFORM_REQUEST';
export const UPDATE_MAPSTYLE = 'map/UPDATE_MAPSTYLE';
export const UPDATE_FEATURE_INFO_HOVER = 'map/UPDATE_FEATURE_INFO_HOVER';
export const UPDATE_FEATURE_INFO_CLICK = 'map/UPDATE_FEATURE_INFO_CLICK';
export const TOGGLE_FILTERS = 'map/TOGGLE_FILTERS';
export const TOGGLE_OBJECTS = 'map/TOGGLE_OBJECTS';
export const TOGGLE_TRACKSOURCE = 'map/TOGGLE_TRACKSOURCE';
export const TOGGLE_SEARCHLOADING = 'map/TOGGLE_SEARCHLOADING';
export const UPDATE_LAYER_PROPERTY = 'map/UPDATE_LAYER_PROPERTY';
export const UPDATE_ELEMENTS_LIST = 'map/UPDATE_ELEMENTS_LIST';
export const ADD_SELECTED_ELEMENTS = 'map/ADD_SELECTED_ELEMENTS';
export const REMOVE_SELECTED_ELEMENTS = 'map/REMOVE_SELECTED_ELEMENTS';
export const SELECT_CHILD_ELEMENT = 'map/SELECT_CHILD_ELEMENT';
export const UNSELECT_CHILD_ELEMENT = 'map/UNSELECT_CHILD_ELEMENT';
export const UPDATE_SEARCH_OBJECTS = 'map/UPDATE_SEARCH_OBJECTS';
export const UPDATE_MODE = 'map/UPDATE_MODE';
export const UPDATE_SEARCH = 'map/UPDATE_SEARCH';
export const UPDATE_SEARCH_RESULTS = 'map/UPDATE_SEARCH_RESULTS';
export const UPDATE_POPUP_CONTENT = 'map/UPDATE_POPUP_CONTENT';
export const UPDATE_POPUP_CONTENT_PROPERTY = 'map/UPDATE_POPUP_CONTENT_PROPERTY';
export const DELETE_POPUP_CONTENT_PROPERTY = 'map/DELETE_POPUP_CONTENT_PROPERTY';
export const UPDATE_DRAG_AND_DROP = 'map/UPDATE_DRAG_AND_DROP';
export const UPDATE_USER_PREFERENCE = 'map/UPDATE_USER_PREFERENCE';
export const UPDATE_SELECTED_ZONE = 'map/UPDATE_SELECTED_ZONE';
export const DELETE_OBJECT = 'map/DELETE_OBJECT';
export const UPDATE_ERROR = 'map/UPDATE_ERROR';
export const UPDATE_SIDEBAR_TAB = 'map/SIDEBAR_TAB';
export const UPDATE_COORDINATES = 'map/UPDATE_COORDINATES';
export const GET_POSTES = 'map/GET_POSTES';
export const GET_POSTES_METIER = 'map/GET_POSTES_METIER';
export const GET_ITINERAIRES = 'map/GET_ITINERAIRES';
export const GET_TABLES_ASSOCIEES = 'map/GET_TABLES_ASSOCIEES';
export const GET_TABLES_RESPONSABLES = 'map/GET_TABLES_RESPONSABLES';
export const UPDATE_FILTER_VALUE = 'map/UPDATE_FILTER_VALUE';
export const UPDATE_FILTERED_POSTES_METIER = 'map/UPDATE_FILTERED_POSTES_METIER';
export const UPDATE_FILTERED_ITINERAIRES = 'map/UPDATE_FILTERED_ITINERAIRES';
export const UPDATE_FILTERED_TABLES_ASSOCIEES = 'map/UPDATE_FILTERED_TABLES_ASSOCIEES';
export const UPDATE_FILTERED_TABLES_RESPONSABLES = 'map/UPDATE_FILTERED_TABLES_RESPONSABLES';
export const UPDATE_ITINERAIRES_IMPACTES = 'map/UPDATE_ITINERAIRES_IMPACTES';
export const UPDATE_PERIMETRES_ASSOCIES = 'map/UPDATE_PERIMETRES_ASSOCIES';
export const UPDATE_PERIMETRES_RESPONSABLES = 'map/UPDATE_PERIMETRES_RESPONSABLES';
// Create objects
export const UPDATE_SELECTED_OBJECT_TO_CREATE = 'map/UPDATE_SELECTED_OBJECT_TO_CREATE';
export const UPDATE_OBJECT_TO_CREATE = 'map/UPDATE_OBJECT_TO_CREATE';
export const UPDATE_SELECTED_SOURCE_OBJECT = 'map/UPDATE_SELECTED_SOURCE_OBJECT';
export const UPDATE_VALIDATE_SIGNAL = 'map/UPDATE_VALIDATE_SIGNAL';
export const UPDATE_VALIDATE_BRANCHE = 'map/UPDATE_VALIDATE_BRANCHE';
// Modify objects
export const UPDATE_SELECTED_PROPERTY = 'map/UPDATE_SELECTED_PROPERTY';
// Split TIV
export const UPDATE_SPLIT_PK = 'map/UPDATE_SPLIT_PK';

const initElements = (initialObjects = [], initialGroups = []) => ({
  objects: initialObjects,
  groups: initialGroups,
});

// Reducer
export const initialState = {
  ref: undefined,
  mode: MAP_MODES.display,
  url: MAP_URL,
  mapStyle: mapStyleEmptyJSON,
  trackSource: MAP_TRACK_SOURCES.geographic,
  viewport: {
    ...DEFAULT_VIEWPORT,
    transformRequest: (url, resourceType) => transformRequest(url, resourceType),
  },
  featureInfoHoverID: undefined,
  featureInfoClickID: undefined,
  featureSource: undefined,
  filters: {
    shown: false,
    osm: false,
  },
  elements: {
    search: '',
    shown: false,
    all: initElements(),
    selected: initElements(),
    filtered: initElements(),
  },
  layers: {
    vs: {
      visible: true,
    },
    vp: {
      visible: true,
    },
    communes: {
      visible: false,
    },
  },
  // Popup
  selectedObjectLayer: undefined,
  popupContent: {},
  newRows: {},
  dragAndDrop: false,
  userPreference: undefined,

  // Displayed zone
  selectedZone: undefined,

  // Create objects
  selectedObjectToCreate: undefined,
  objectToCreate: {},
  selectedSourceObject: {},
  validateSignal: false,
  validateBranche: false,

  // Modify objects
  selectedProperty: undefined,

  // Split TIV
  splitPk: '',

  // Search
  search: '',
  searchResults: [],
  isSearchLoading: false,

  error: null,
  sidebarTab: '#geom',

  coordinates: {},
  postes: [],
  postesMetier: [],
  itineraires: [],
  tablesAssociees: [],
  tablesResponsables: [],
  filterValue: '',
  filteredPostesMetier: [],
  filteredItineraires: [],
  filteredTablesAssociees: [],
  filteredTablesResponsables: [],
  itinerairesImpactes: [],
  perimetresAssocies: [],
  perimetresResponsables: [],
};

export default function reducer(state = initialState, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case UPDATE_VIEWPORT:
        draft.viewport.width = action.viewport.width;
        draft.viewport.height = action.viewport.height;
        draft.viewport.latitude = action.viewport.latitude;
        draft.viewport.longitude = action.viewport.longitude;
        draft.viewport.zoom = action.viewport.zoom;
        draft.viewport.bearing = action.viewport.bearing;
        draft.viewport.pitch = action.viewport.pitch;
        draft.viewport.transitionDuration = action.viewport.transitionDuration;
        draft.viewport.transitionInterpolator = action.viewport.transitionInterpolator;
        break;
      case UPDATE_REF:
        draft.ref = action.ref;
        break;
      case UPDATE_TRANSFORM_REQUEST:
        draft.viewport.transformRequest = (url, resourceType) => (
          transformRequest(url, resourceType, MAP_URL)
        );
        break;
      case UPDATE_MAPSTYLE:
        draft.mapStyle = action.mapStyle;
        draft.filters[action.styleName] = !state.filters[action.styleName];
        break;
      case UPDATE_FEATURE_INFO_HOVER:
        draft.featureSource = action.featureSource;
        draft.featureInfoHoverID = action.featureInfoHoverID;
        break;
      case UPDATE_FEATURE_INFO_CLICK:
        draft.featureInfoClickID = action.featureInfoClickID;
        break;
      case TOGGLE_FILTERS:
        draft.elements.shown = false;
        draft.filters.shown = !state.filters.shown;
        break;
      case TOGGLE_OBJECTS:
        draft.filters.shown = false;
        draft.elements.shown = !state.elements.shown;
        break;
      case TOGGLE_TRACKSOURCE:
        draft.trackSource = action.trackSource;
        break;
      case UPDATE_LAYER_PROPERTY:
        draft.layers[action.layer][action.property] = action.value;
        break;
      case UPDATE_ELEMENTS_LIST:
        draft.elements.all = action.elements;
        break;
      case ADD_SELECTED_ELEMENTS:
        if (!draft.elements.selected[action.elementType].find((el) => el.key === action.element.key)) {
          draft.elements.selected[action.elementType].push(action.element);
        }
        break;
      case REMOVE_SELECTED_ELEMENTS:
        draft.elements.selected[action.elementType] = draft.elements.selected[action.elementType]
          .filter((el) => el.key !== action.element.key);
        break;
      case SELECT_CHILD_ELEMENT:
        updateChildElementSelect(draft, action, true);
        break;
      case UNSELECT_CHILD_ELEMENT:
        updateChildElementSelect(draft, action, false);
        break;
      case UPDATE_SEARCH_OBJECTS:
        draft.elements.search = action.search;
        // For each element type, we filter each array with the search word
        Object.keys(draft.elements.filtered).forEach((elementType) => {
          draft.elements.filtered[elementType] = filterBySearch(
            draft.elements.all[elementType],
            action.search,
            'label',
          );
        });
        break;
      case UPDATE_POPUP_CONTENT:
        draft.popupContent = action.popupContent;
        if (action.selectedObjectLayer !== undefined) {
          draft.selectedObjectLayer = action.selectedObjectLayer;
        }
        break;
      case UPDATE_POPUP_CONTENT_PROPERTY:
        set(draft.popupContent, action.path, action.value);
        break;
      case DELETE_POPUP_CONTENT_PROPERTY:
        if (Array.isArray(getObj(draft.popupContent, dropRight(action.path)))) {
          pullAt(getObj(draft.popupContent, dropRight(action.path)), last(action.path));
        } else {
          unset(draft.popupContent, action.path);
        }
        break;
      case UPDATE_MODE:
        draft.mode = action.mode;
        break;
      case UPDATE_SEARCH:
        draft.search = action.search;
        break;
      case UPDATE_SEARCH_RESULTS:
        draft.searchResults = action.results;
        break;
      case TOGGLE_SEARCHLOADING:
        draft.isSearchLoading = action.isSearchLoading;
        break;
      case UPDATE_DRAG_AND_DROP:
        draft.dragAndDrop = action.dragAndDrop;
        break;
      case UPDATE_USER_PREFERENCE:
        draft.userPreference = action.userPreference;
        break;
      case UPDATE_SELECTED_ZONE:
        draft.selectedZone = action.zone;
        break;
      case UPDATE_SELECTED_OBJECT_TO_CREATE:
        draft.selectedObjectToCreate = action.selectedObjectToCreate;
        break;
      case UPDATE_OBJECT_TO_CREATE:
        draft.objectToCreate = action.objectToCreate;
        break;
      case UPDATE_SELECTED_SOURCE_OBJECT:
        draft.selectedSourceObject = action.selectedSourceObject;
        break;
      case UPDATE_VALIDATE_SIGNAL:
        draft.validateSignal = action.validateSignal;
        break;
      case UPDATE_VALIDATE_BRANCHE:
        draft.validateBranche = action.validateBranche;
        break;
      case UPDATE_SELECTED_PROPERTY:
        draft.selectedProperty = action.selectedProperty;
        break;
      case UPDATE_ERROR:
        draft.error = action.error;
        break;
      case UPDATE_SIDEBAR_TAB:
        draft.sidebarTab = action.sidebarTab;
        break;
      case UPDATE_COORDINATES:
        draft.coordinates = action.coordinates;
        break;
      case GET_POSTES:
        draft.postes = action.postes;
        break;
      case GET_POSTES_METIER:
        draft.postesMetier = action.postesMetier;
        break;
      case GET_ITINERAIRES:
        draft.itineraires = action.itineraires;
        break;
      case GET_TABLES_ASSOCIEES:
        draft.tablesAssociees = action.tablesAssociees;
        break;
      case GET_TABLES_RESPONSABLES:
        draft.tablesResponsables = action.tablesResponsables;
        break;
      case UPDATE_FILTER_VALUE:
        draft.filterValue = action.filterValue;
        break;
      case UPDATE_FILTERED_POSTES_METIER:
        draft.filteredPostesMetier = action.filteredPostesMetier;
        break;
      case UPDATE_FILTERED_ITINERAIRES:
        draft.filteredItineraires = action.filteredItineraires;
        break;
      case UPDATE_FILTERED_TABLES_ASSOCIEES:
        draft.filteredTablesAssociees = action.filteredTablesAssociees;
        break;
      case UPDATE_FILTERED_TABLES_RESPONSABLES:
        draft.filteredTablesResponsables = action.filteredTablesResponsables;
        break;
      case UPDATE_ITINERAIRES_IMPACTES:
        draft.itinerairesImpactes = action.itinerairesImpactes;
        break;
      case UPDATE_PERIMETRES_ASSOCIES:
        draft.perimetresAssocies = action.perimetresAssocies;
        break;
      case UPDATE_PERIMETRES_RESPONSABLES:
        draft.perimetresResponsables = action.perimetresResponsables;
        break;
      case UPDATE_SPLIT_PK:
        draft.splitPk = action.splitPk;
        break;
    }
  });
}

// Action Creators
function updateViewportAction(viewport) {
  return {
    type: UPDATE_VIEWPORT,
    viewport,
  };
}

export function addSelectedElement(element, elementType) {
  return {
    type: ADD_SELECTED_ELEMENTS,
    element,
    elementType,
  };
}

function removeSelectedElement(element, elementType) {
  return {
    type: REMOVE_SELECTED_ELEMENTS,
    element,
    elementType,
  };
}

function selectChildElement(parent, child, elementType) {
  return {
    type: SELECT_CHILD_ELEMENT,
    parent,
    child,
    elementType,
  };
}

function unselectChildElement(parent, child, elementType) {
  return {
    type: UNSELECT_CHILD_ELEMENT,
    parent,
    child,
    elementType,
  };
}

export function updateSearchResultsAction(results) {
  return {
    type: UPDATE_SEARCH_RESULTS,
    results,
  };
}

function toggleSearchLoadingAction(isSearchLoading) {
  return {
    type: TOGGLE_SEARCHLOADING,
    isSearchLoading,
  };
}

function getPostesAction(postes) {
  return {
    type: GET_POSTES,
    postes,
  };
}

function getPostesMetierAction(postesMetier) {
  return {
    type: GET_POSTES_METIER,
    postesMetier,
  };
}

function getItinerairesAction(itineraires) {
  return {
    type: GET_ITINERAIRES,
    itineraires,
  };
}

function getTablesAssocieesAction(tablesAssociees) {
  return {
    type: GET_TABLES_ASSOCIEES,
    tablesAssociees,
  };
}

function getTablesResponsablesAction(tablesResponsables) {
  return {
    type: GET_TABLES_RESPONSABLES,
    tablesResponsables,
  };
}

function updateFilterValueAction(filterValue) {
  return {
    type: UPDATE_FILTER_VALUE,
    filterValue,
  };
}

function updateFilteredPostesMetierAction(filteredPostesMetier) {
  return {
    type: UPDATE_FILTERED_POSTES_METIER,
    filteredPostesMetier,
  };
}

function updateFilteredItinerairesAction(filteredItineraires) {
  return {
    type: UPDATE_FILTERED_ITINERAIRES,
    filteredItineraires,
  };
}

function updateFilteredTablesAssocieesAction(filteredTablesAssociees) {
  return {
    type: UPDATE_FILTERED_TABLES_ASSOCIEES,
    filteredTablesAssociees,
  };
}

function updateFilteredTablesResponsablesAction(filteredTablesResponsables) {
  return {
    type: UPDATE_FILTERED_TABLES_RESPONSABLES,
    filteredTablesResponsables,
  };
}

// Functions
export function updateRef(ref) {
  return {
    type: UPDATE_REF,
    ref,
  };
}

export function updateSelectedProperty(selectedProperty) {
  return {
    type: UPDATE_SELECTED_PROPERTY,
    selectedProperty,
  };
}

export function updateViewport(viewport, baseUrl = undefined, updateRouter = true) {
  return (dispatch) => {
    dispatch(updateViewportAction(viewport));
    if (baseUrl !== undefined && updateRouter) {
      history.push(`${baseUrl}/${gpsRound(viewport.latitude)}/${gpsRound(viewport.longitude)}/${gpsRound(viewport.zoom)}/${gpsRound(viewport.bearing)}/${gpsRound(viewport.pitch)}`);
    }
  };
}

export function updateMapStyle(mapStyle, styleName) {
  return (dispatch) => {
    dispatch({
      type: UPDATE_MAPSTYLE,
      mapStyle,
      styleName,
    });
  };
}

export function updateFeatureInfoHover(featureInfoHoverID, featureSource) {
  return (dispatch) => {
    dispatch({
      type: UPDATE_FEATURE_INFO_HOVER,
      featureInfoHoverID,
      featureSource,
    });
  };
}

export function updateFeatureInfoClick(featureInfoClickID, featureSource) {
  return (dispatch) => {
    dispatch({
      type: UPDATE_FEATURE_INFO_CLICK,
      featureInfoClickID,
      featureSource,
    });
  };
}

export function toggleFilters() {
  return (dispatch) => {
    dispatch({
      type: TOGGLE_FILTERS,
    });
  };
}

export function toggleObjects() {
  return (dispatch) => {
    dispatch({
      type: TOGGLE_OBJECTS,
    });
  };
}

export function toggleTrackSource(trackSource) {
  return (dispatch) => {
    dispatch({
      type: TOGGLE_TRACKSOURCE,
      trackSource,
    });
  };
}

export function updateError(error, errorMessage) {
  console.log(errorMessage);
  if (error !== null) { console.log(error.response.status, error.response.data); }
  return (dispatch) => {
    dispatch({
      type: UPDATE_ERROR,
      error,
    });
  };
}

export function updateSelectedElement(element, elementType, isSelected) {
  return (dispatch) => {
    if (isSelected) {
      dispatch(addSelectedElement(element, elementType));
    } else {
      dispatch(removeSelectedElement(element, elementType));
    }
  };
}

export function updateSelectedChildElement(parent, child, elementType, isSelected) {
  return (dispatch) => {
    if (isSelected) {
      dispatch(selectChildElement(parent, child, elementType));
    } else {
      dispatch(unselectChildElement(parent, child, elementType));
    }
  };
}

export function updateLayerProperty(layer, property, value) {
  return (dispatch) => {
    dispatch({
      type: UPDATE_LAYER_PROPERTY,
      layer,
      property,
      value,
    });
  };
}

export function updateSearchObjects(search) {
  return (dispatch) => {
    dispatch({
      type: UPDATE_SEARCH_OBJECTS,
      search,
    });
  };
}

export function updateSplitPk(splitPk) {
  return (dispatch) => {
    dispatch({
      type: UPDATE_SPLIT_PK,
      splitPk,
    });
  };
}

export function updateMode(mode) {
  return (dispatch) => {
    dispatch({
      type: UPDATE_MODE,
      mode,
    });
  };
}

export function updateSearch(search) {
  return async (dispatch) => {
    dispatch({
      type: UPDATE_SEARCH,
      search,
    });

    if (search === '') {
      dispatch(updateSearchResultsAction([]));
    }
  };
}
export function mockUpdateSearchResults(results) {
  return (dispatch) => {
    try {
      dispatch(toggleSearchLoadingAction(true));
      dispatch(updateSearchResultsAction(results));
      dispatch(toggleSearchLoadingAction(false));
    } catch (e) {
      console.log(e);
    }
  };
}
export function updateSearchResults(bbox) {
  return async (dispatch, getState) => {
    const {
      map: { elements, search, selectedZone },
    } = getState();
    const params = {
      search,
      geo_field: 'geomsch',
      layer_slug: elements.selected.objects.map((el) => el.sourceTable),
      zone: selectedZone ? JSON.stringify(selectedZone.geometry) : JSON.stringify(bbox),
    };
    try {
      dispatch(toggleSearchLoadingAction(true));
      const { results } = await get('/midi/search/', params, true);
      dispatch(updateSearchResultsAction(results.features || []));
      dispatch(toggleSearchLoadingAction(false));
    } catch (e) {
      console.log(e);
    }
  };
}

export function updatePopupContentProperty(path, value) {
  return (dispatch) => {
    dispatch({
      type: UPDATE_POPUP_CONTENT_PROPERTY,
      path,
      value,
    });
  };
}

export function deletePopupContentProperty(path) {
  return (dispatch) => {
    dispatch({
      type: DELETE_POPUP_CONTENT_PROPERTY,
      path,
    });
  };
}

export function updatePopupContent(popupContent, selectedObjectLayer) {
  return (dispatch) => {
    dispatch({
      type: UPDATE_POPUP_CONTENT,
      popupContent,
      selectedObjectLayer,
    });
  };
}

export function updateDragAndDrop(dragAndDrop) {
  return (dispatch) => {
    dispatch({
      type: UPDATE_DRAG_AND_DROP,
      dragAndDrop,
    });
  };
}

export function updateUserPreference(userPreference) {
  return (dispatch) => {
    dispatch({
      type: UPDATE_USER_PREFERENCE,
      userPreference,
    });
  };
}

export function updateSelectedZone(zone) {
  return (dispatch) => {
    dispatch({
      type: UPDATE_SELECTED_ZONE,
      zone,
    });
  };
}

export function updateSelectedObjectToCreate(selectedObjectToCreate) {
  return (dispatch) => {
    dispatch({
      type: UPDATE_SELECTED_OBJECT_TO_CREATE,
      selectedObjectToCreate,
    });
  };
}

export function updateObjectToCreate(objectToCreate) {
  return (dispatch) => {
    dispatch({
      type: UPDATE_OBJECT_TO_CREATE,
      objectToCreate,
    });
  };
}

export function updateSelectedSourceObject(selectedSourceObject) {
  return (dispatch) => {
    dispatch({
      type: UPDATE_SELECTED_SOURCE_OBJECT,
      selectedSourceObject,
    });
  };
}

export function updateValidateSignal(validateSignal) {
  return (dispatch) => {
    dispatch({
      type: UPDATE_VALIDATE_SIGNAL,
      validateSignal,
    });
  };
}

export function updateValidateBranche(validateBranche) {
  return (dispatch) => {
    dispatch({
      type: UPDATE_VALIDATE_BRANCHE,
      validateBranche,
    });
  };
}

export function updateObject(modification) {
  return async (dispatch) => {
    try {
      const updatedObject = await post('/midi/modification/', modification);
      console.log('updatedObject', updatedObject);
      dispatch(updatePopupContent(updatedObject.new_gaia_object));
      return updatedObject;
    } catch (err) {
      console.log(err.response.status, err.response.data);
      dispatch(updateError(err, 'update Error'));
      return null;
    }
  };
}

export function createObject(modification) {
  return async (dispatch) => {
    try {
      const createdObject = await post('/midi/modification/', modification);
      console.log(createdObject);
      return createdObject;
    } catch (err) {
      dispatch(updateError(err, 'Object Creation Error'));
      return undefined;
    }
  };
}

export function deleteObjects(modification) {
  return async (dispatch) => {
    try {
      const res = await post('/midi/modification/', modification);
      dispatch({
        type: DELETE_OBJECT,
        modification,
      });
      console.log(res);
      return res;
    } catch (err) {
      dispatch(updateError(err, 'Object Deletion Error'));
    }
  };
}

export const updateElementsList = (objects, groups) => (dispatch) => {
  dispatch({
    type: UPDATE_ELEMENTS_LIST,
    elements: initElements(objects.filter((o) => !o.hide && o.key !== MIDI_OBJECTS_KEYS.poste), groups),
  });
};

export function updateSideBarTab(sidebarTab) {
  return (dispatch) => {
    dispatch({
      type: UPDATE_SIDEBAR_TAB,
      sidebarTab,
    });
  };
}

export function updateCoordinates(coordinates) {
  return (dispatch) => {
    dispatch({
      type: UPDATE_COORDINATES,
      coordinates,
    });
  };
}

function updateFilteredItems(itemsType) {
  return (dispatch, getState) => {
    const { map } = getState();
    const {
      filterValue, postesMetier, itineraires, tablesAssociees, tablesResponsables,
    } = map;

    let filteredItems = [];
    switch (itemsType) {
      case ITEMS_TYPES.postesMetier:
        filteredItems = postesMetier;
        break;
      case ITEMS_TYPES.itineraires:
        filteredItems = itineraires;
        break;
      case ITEMS_TYPES.tablesAssociees:
        filteredItems = tablesAssociees;
        break;
      default:
        filteredItems = tablesResponsables;
    }

    if (filterValue !== '') {
      if (itemsType === ITEMS_TYPES.tablesAssociees || itemsType === ITEMS_TYPES.tablesResponsables) {
        filteredItems = filterBySearch(filteredItems, filterValue, 'table');
      } else {
        filteredItems = filterBySearch(filteredItems, filterValue, 'RA_libelle');
      }
    }
    if (itemsType === ITEMS_TYPES.postesMetier) {
      dispatch(updateFilteredPostesMetierAction(filteredItems));
    } else if (itemsType === ITEMS_TYPES.itineraires) {
      dispatch(updateFilteredItinerairesAction(filteredItems));
    } else if (itemsType === ITEMS_TYPES.tablesAssociees) {
      dispatch(updateFilteredTablesAssocieesAction(filteredItems));
    } else {
      dispatch(updateFilteredTablesResponsablesAction(filteredItems));
    }
  };
}

export function getPostes(source, coordinates) {
  return async (dispatch) => {
    try {
      const res = await get(`/chartisv1/layer/map_midi_poste/geojson/${source}/`, coordinates);
      const postesList = [];
      res.features.forEach((poste) => postesList.push(poste.properties));
      dispatch(getPostesAction(postesList));
      return res;
    } catch (err) {
      dispatch(updateError(err, 'get postes Error'));
      return undefined;
    }
  };
}

export function getPostesMetier() {
  return async (dispatch) => {
    try {
      const res = await get('/chartisv1/layer/map_midi_poste_metier/json/all/');
      dispatch(getPostesMetierAction(res.features));
      dispatch(updateFilteredItems(ITEMS_TYPES.postesMetier));
      return res;
    } catch (err) {
      dispatch(updateError(err, 'get postes metier Error'));
      return undefined;
    }
  };
}

export function getItineraires(source, coordinates) {
  return async (dispatch) => {
    try {
      const res = await get(`/chartisv1/layer/map_midi_itineraire/geojson/${source}/`, coordinates);
      const itinerairesList = [];
      res.features.forEach((itineraire) => {
        if (itineraire.properties.RA_libelle !== null) {
          itinerairesList.push(itineraire.properties);
        }
      });
      dispatch(getItinerairesAction(itinerairesList));
      dispatch(updateFilteredItems(ITEMS_TYPES.itineraires));
      return res;
    } catch (err) {
      dispatch(updateError(err, 'get itineraires Error'));
      return undefined;
    }
  };
}

export function updateFilterValue(filterValue, itemsType) {
  return (dispatch) => {
    dispatch(updateFilterValueAction(filterValue));
    dispatch(updateFilteredItems(itemsType));
  };
}

export function updateItinerairesImpactes(itinerairesImpactes) {
  return (dispatch) => {
    dispatch({
      type: UPDATE_ITINERAIRES_IMPACTES,
      itinerairesImpactes,
    });
  };
}

export function updatePerimetresAssocies(perimetresAssocies) {
  return (dispatch, getState) => {
    const { map } = getState();
    const { postesMetier } = map;
    const tables = [];
    dispatch({
      type: UPDATE_PERIMETRES_ASSOCIES,
      perimetresAssocies,
    });
    if (typeof perimetresAssocies !== 'string' && postesMetier.length !== 0) {
      if (perimetresAssocies.length !== 1 || perimetresAssocies[0].OP_id_poste_metier !== '') {
        perimetresAssocies.forEach((posteAssoc) => {
          const tempPosteMetier = postesMetier.filter((poste) => poste.OP_id === posteAssoc.OP_id_poste_metier)[0];
          if (tables.filter((table) => table.poste_id === posteAssoc.OP_id_poste_metier).length === 0) {
            JSON.parse(tempPosteMetier?.tables).forEach((table) => tables.push({
              poste_id: tempPosteMetier.OP_id,
              poste_libelle: tempPosteMetier.RA_libelle,
              table: table.trim(),
            }));
          }
        });
      }
    }
    dispatch(getTablesAssocieesAction(Array.from(new Set(tables))));
    dispatch(updateFilteredTablesAssocieesAction(Array.from(new Set(tables))));
  };
}

export function updatePerimetresResponsables(perimetresResponsables) {
  return (dispatch, getState) => {
    const { map } = getState();
    const { postesMetier } = map;
    const tables = [];
    dispatch({
      type: UPDATE_PERIMETRES_RESPONSABLES,
      perimetresResponsables,
    });
    if (typeof perimetresResponsables !== 'string' && postesMetier.length !== 0) {
      if (perimetresResponsables.length !== 1 || perimetresResponsables[0].OP_id_poste_metier !== '') {
        perimetresResponsables.forEach((posteResp) => {
          const tempPosteMetier = postesMetier.filter((poste) => poste.OP_id === posteResp.OP_id_poste_metier)[0];
          if (tables.filter((table) => table.poste_id === posteResp.OP_id_poste_metier).length === 0) {
            JSON.parse(tempPosteMetier?.tables).forEach((table) => tables.push({
              poste_id: tempPosteMetier.OP_id,
              poste_libelle: tempPosteMetier.RA_libelle,
              table: table.trim(),
            }));
          }
        });
      }
    }
    const tablesResponsables = Array.from(new Set(tables));
    dispatch(getTablesResponsablesAction(tablesResponsables));
    dispatch(updateFilteredTablesResponsablesAction(Array.from(new Set(tables))));
  };
}
