export const MAIN_API = {
  proxy: 'https://gateway.dgexsol.fr',
  api: 'https://gateway.dgexsol.fr',
  version: '0.0.1.20200408.1',
};

export const DIGITIZATION_API = {
  api: 'https://digitization.dgexsol.fr/api/v1',
};

export const GATEWAY_API = {
  api: 'https://gateway.shared.dgexsol.fr',
};

export default MAIN_API;
