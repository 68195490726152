import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import SelectSNCF from 'common/BootstrapSNCF/SelectSNCF';
import {
  MAP_MODES, FIELDS_LABEL, GEOM_FIELDS, ATTRIB_FIELDS, GEOM_CREATION,
  ATTRIB_CREATION, DETAILS_TABLE_FIELDS, NEW_PERIMETRE,
} from 'common/Map/const';
import { difference, get, isEqual } from 'lodash';
import nextId from 'react-id-generator';
import { MIDI_OBJECTS_KEYS, MIDI_OBJECTS_LAYERS } from 'common/Map/Consts/MidiObjects';
import {
  deletePopupContentProperty, getItineraires, getPostes,
  getPostesMetier, updateItinerairesImpactes,
  updatePerimetresAssocies, updatePerimetresResponsables,
  updatePopupContent, updatePopupContentProperty,
} from 'reducers/map';

import './DetailsTable.css';

const Table = () => {
  const dispatch = useDispatch();
  const map = useSelector((state) => state.map);
  const mapRef = useRef(map);
  const { t } = useTranslation();

  const totalCount = (prop1, prop2) => {
    const length1 = map.popupContent[prop1]?.length || 0;
    const length2 = map.popupContent[prop2]?.length || 0;

    return length1 + length2;
  };

  const formatJSONContent = () => {
    // Parse every JSON of the object
    let updatedData = map.popupContent;
    Object.entries(map.popupContent).forEach(([key, value]) => {
      try {
        const parsedValue = JSON.parse(value);
        // eslint-disable-next-line no-restricted-globals
        if (!isNaN(value)) {
          throw new Error();
        }
        updatedData = {
          ...updatedData,
          [key]: parsedValue,
        };
      } catch (e) {
        // console.log(e);
      }
    });
    dispatch(updatePopupContent(updatedData));
  };

  useEffect(() => formatJSONContent(), [map.popupContent]);

  useEffect(() => {
    if (map.mode !== MAP_MODES.creation && !isEqual(mapRef.current.popupContent, map.popupContent)) {
      formatJSONContent();
    }
  }, [map.popupContent]);

  useEffect(() => {
    async function getPostesList() {
      if (map.coordinates !== {}) await dispatch(getPostes('sch', map.coordinates));
    }
    async function getPostesMetierList() {
      await dispatch(getPostesMetier());
    }
    async function getItinerairesList() {
      if (map.coordinates !== {} && (Object.keys(map.popupContent).includes(DETAILS_TABLE_FIELDS.cles_protections)
            || map.selectedObjectLayer.key === MIDI_OBJECTS_KEYS.itineraire)) {
        await dispatch(getItineraires('sch', map.coordinates));
      }
    }
    getPostesList();
    getPostesMetierList();
    getItinerairesList();
  }, [map.coordinates]);

  useEffect(() => {
    if (map.mode !== MAP_MODES.creation && Object.keys(map.popupContent).includes(DETAILS_TABLE_FIELDS.itineraires_impactes)) {
      dispatch(updateItinerairesImpactes(map.popupContent.itineraires_impactes));
    }
  }, [map.popupContent.itineraires_impactes]);

  useEffect(() => {
    if (map.mode !== MAP_MODES.creation && Object.keys(map.popupContent).includes(DETAILS_TABLE_FIELDS.perimetres_associes)) {
      dispatch(updatePerimetresAssocies(map.popupContent.perimetres_associes));
    }
  }, [map.popupContent.perimetres_associes]);

  useEffect(() => {
    if (map.mode !== MAP_MODES.creation && Object.keys(map.popupContent).includes(DETAILS_TABLE_FIELDS.perimetres_responsables)) {
      dispatch(updatePerimetresResponsables(map.popupContent.perimetres_responsables));
    }
  }, [map.popupContent.perimetres_responsables]);

  const onChange = (value, path, key = undefined) => {
    if (key) {
      value = JSON.parse(value)[key];
    }

    dispatch(updatePopupContentProperty(path, value));
  };

  const getKey = (label) => {
    switch (label) {
      case FIELDS_LABEL.RA_poste:
        return 'RA_libelle';
      default:
        return 'key';
    }
  };

  const onSelect = (value, element, path) => {
    if (element.label === FIELDS_LABEL.TIF_mnemo && element.key !== 'TADV_mnemonique') onChange(value, ['OP_id_typeinstallationfixe'], 'id');
    if (element.label === FIELDS_LABEL.TIF_mnemo && element.key === 'TADV_mnemonique') onChange(value, ['OP_id_typeappareildevoie'], 'id');
    if (element.label === FIELDS_LABEL.perimetres_associes && value !== undefined) {
      const oldPostes = [];

      map.perimetresAssocies.forEach((poste) => {
        if (poste.OP_id_poste_metier !== '' && oldPostes.filter((oldPoste) => oldPoste.OP_id_poste_metier === poste.OP_id_poste_metier).length === 0) {
          oldPostes.push(poste);
        }
        return oldPostes;
      });
      if (value.length === 0) oldPostes.push(NEW_PERIMETRE);
      map.perimetresAssocies.forEach((perimetre) => {
        if (!value.find((poste) => poste[0].OP_id === perimetre.OP_id_poste_metier)) {
          oldPostes.splice(oldPostes.findIndex((p) => p.OP_id_poste_metier === perimetre.OP_id_poste_metier), 1);
        }
      });
      value.forEach((poste) => {
        if (!map.perimetresAssocies.find((p) => p.OP_id_poste_metier === poste[0].OP_id)) {
          oldPostes.push({
            OP_id_poste_metier: poste[0].OP_id,
            RA_libelle_poste_metier: poste[0].RA_libelle,
          });
        }
      });

      dispatch(updatePerimetresAssocies(oldPostes));
    }
    if (element.label === FIELDS_LABEL.perimetres_responsables && value !== undefined) {
      const oldPostes = [];

      map.perimetresResponsables.forEach((poste) => {
        if (poste.OP_id_poste_metier !== '' && oldPostes.filter((oldPoste) => oldPoste.OP_id_poste_metier === poste.OP_id_poste_metier).length === 0) {
          oldPostes.push(poste);
        }
        return oldPostes;
      });
      if (value.length === 0) oldPostes.push(NEW_PERIMETRE);
      map.perimetresResponsables.forEach((perimetre) => {
        if (!value.find((poste) => poste[0].OP_id === perimetre.OP_id_poste_metier)) {
          oldPostes.splice(oldPostes.findIndex((p) => p.OP_id_poste_metier === perimetre.OP_id_poste_metier), 1);
        }
      });
      value.forEach((poste) => {
        if (!map.perimetresResponsables.find((p) => p.OP_id_poste_metier === poste[0].OP_id)) {
          oldPostes.push({
            OP_id_poste_metier: poste[0].OP_id,
            RA_libelle_poste_metier: poste[0].RA_libelle,
          });
        }
      });

      dispatch(updatePerimetresResponsables(oldPostes));
    }
    if (element.label === FIELDS_LABEL.tables_associees) {
      const oldPerimetres = [];
      const perimetresAssocies = [];

      map.perimetresAssocies.forEach((perimetre) => {
        if (perimetre.table !== '' && value.length !== 0
            && value.filter((table) => table.table === perimetre.table?.table && table.poste_id === perimetre.OP_id_poste_metier).length === 0
            && map.perimetresAssocies.filter((p) => p.OP_id_poste_metier === perimetre.OP_id_poste_metier).length <= 1) {
          oldPerimetres.push({ ...perimetre, table: '' });
        }
      });

      if (value.length === 0) {
        map.perimetresAssocies.forEach((perimetre) => {
          if (map.perimetresAssocies.filter((p) => p.OP_id_poste_metier === perimetre.OP_id_poste_metier).length <= 1) {
            if (perimetre.table !== '') {
              oldPerimetres.push({ OP_id_poste_metier: perimetre.OP_id_poste_metier, RA_libelle_poste_metier: perimetre.RA_libelle_poste_metier });
            } else {
              oldPerimetres.push({ ...perimetre });
            }
          }
        });
      }

      value.forEach((table) => {
        map.perimetresAssocies.forEach((perimetre) => {
          const { tables } = map.postesMetier.filter((poste) => poste.OP_id === perimetre.OP_id_poste_metier)[0];
          map.perimetresAssocies.filter((oldPerimetre) => oldPerimetre.OP_id_poste_metier === perimetre.OP_id_poste_metier);
          if (JSON.parse(tables).find((tab) => tab.trim() === table.table && table.poste_id === perimetre.OP_id_poste_metier)
          && (perimetre.table === '' || perimetre.table === undefined || value.filter((val) => val.table === perimetre.table.table).length !== 0)) {
            const updatedPerimetre = { ...perimetre, table };
            if (oldPerimetres.filter((oldPerimetre) => oldPerimetre.OP_id_poste_metier === updatedPerimetre.OP_id_poste_metier && oldPerimetre.table === updatedPerimetre.table).length === 0) {
              oldPerimetres.push(updatedPerimetre);
            }
          } else if (oldPerimetres.filter((oldPerimetre) => oldPerimetre.OP_id_poste_metier === perimetre.OP_id_poste_metier).length === 0) {
            oldPerimetres.push(perimetre);
          }
        });
      });

      oldPerimetres.forEach((perimetre) => {
        if (oldPerimetres.filter((perem) => perem.OP_id_poste_metier === perimetre.OP_id_poste_metier).length === 1
         && perimetresAssocies.filter((p) => p.OP_id_poste_metier === perimetre.OP_id_poste_metier).length === 0) {
          perimetresAssocies.push(perimetre);
        } else {
          oldPerimetres.filter((oldPerimetre) => oldPerimetre.table !== '' && oldPerimetre.table !== undefined
            && perimetresAssocies.filter((p) => p.OP_id_poste_metier === oldPerimetre.OP_id_poste_metier && p.table.table === oldPerimetre.table.table).length === 0
            && perimetresAssocies.push(oldPerimetre));
        }
      });

      dispatch(updatePerimetresAssocies(perimetresAssocies));
    }
    if (element.label === FIELDS_LABEL.tables_responsables) {
      const oldPerimetres = [];
      const perimetresResponsables = [];

      map.perimetresResponsables.forEach((perimetre) => {
        if (perimetre.table !== '' && value.length !== 0
            && value.filter((table) => table.table === perimetre.table?.table && table.poste_id === perimetre.OP_id_poste_metier).length === 0
            && map.perimetresResponsables.filter((p) => p.OP_id_poste_metier === perimetre.OP_id_poste_metier).length <= 1) {
          oldPerimetres.push({ ...perimetre, table: '' });
        }
      });

      if (value.length === 0) {
        map.perimetresResponsables.forEach((perimetre) => {
          if (map.perimetresResponsables.filter((p) => p.OP_id_poste_metier === perimetre.OP_id_poste_metier).length <= 1) {
            if (perimetre.table !== '') {
              oldPerimetres.push({ OP_id_poste_metier: perimetre.OP_id_poste_metier, RA_libelle_poste_metier: perimetre.RA_libelle_poste_metier });
            } else {
              oldPerimetres.push({ ...perimetre });
            }
          }
        });
      }

      value.forEach((table) => {
        map.perimetresResponsables.forEach((perimetre) => {
          const { tables } = map.postesMetier.filter((poste) => poste.OP_id === perimetre.OP_id_poste_metier)[0];
          map.perimetresResponsables.filter((oldPerimetre) => oldPerimetre.OP_id_poste_metier === perimetre.OP_id_poste_metier);
          if (JSON.parse(tables).find((tab) => tab.trim() === table.table && table.poste_id === perimetre.OP_id_poste_metier)
          && (perimetre.table === '' || perimetre.table === undefined || value.filter((val) => val.table === perimetre.table.table).length !== 0)) {
            const updatedPerimetre = { ...perimetre, table };
            if (oldPerimetres.filter((oldPerimetre) => oldPerimetre.OP_id_poste_metier === updatedPerimetre.OP_id_poste_metier && oldPerimetre.table === updatedPerimetre.table).length === 0) {
              oldPerimetres.push(updatedPerimetre);
            }
          } else if (oldPerimetres.filter((oldPerimetre) => oldPerimetre.OP_id_poste_metier === perimetre.OP_id_poste_metier).length === 0) {
            oldPerimetres.push(perimetre);
          }
        });
      });

      oldPerimetres.forEach((perimetre) => {
        if (oldPerimetres.filter((perem) => perem.OP_id_poste_metier === perimetre.OP_id_poste_metier).length === 1
         && perimetresResponsables.filter((p) => p.OP_id_poste_metier === perimetre.OP_id_poste_metier).length === 0) {
          perimetresResponsables.push(perimetre);
        } else {
          oldPerimetres.filter((oldPerimetre) => oldPerimetre.table !== '' && oldPerimetre.table !== undefined
            && perimetresResponsables.filter((p) => p.OP_id_poste_metier === oldPerimetre.OP_id_poste_metier && p.table.table === oldPerimetre.table.table).length === 0
            && perimetresResponsables.push(oldPerimetre));
        }
      });

      dispatch(updatePerimetresResponsables(perimetresResponsables));
    }
    if (element.label === FIELDS_LABEL.RA_poste && value !== undefined) {
      const newPopupContent = {
        ...map.popupContent,
        OP_id_poste: JSON.parse(value).OP_id,
        OP_id_gare: JSON.parse(value).OP_id_gare,
        RA_libelle_gare: JSON.parse(value).RA_libelle_gare,
      };
      dispatch(updatePopupContent(newPopupContent, map.selectedObjectLayer));
    }
    if (element.label === FIELDS_LABEL.itineraires_impactes && value !== undefined) {
      const oldItineraires = [];

      map.itinerairesImpactes.forEach((itineraire) => {
        if (itineraire.OP_id !== '') {
          oldItineraires.push(itineraire);
        }
      });

      value.forEach((itineraire) => {
        if (!map.itinerairesImpactes.find((iti) => iti.OP_id === itineraire[0].OP_id)) {
          oldItineraires.push({
            OP_id: itineraire[0].OP_id,
            RA_libelle: itineraire[0].RA_libelle,
          });
        }
      });

      map.itinerairesImpactes.map((iti, index) => {
        if (!map.popupContent.itineraires_impactes.find((itineraire) => itineraire.OP_id === iti.OP_id) && !value.find((itineraire) => itineraire[0].OP_id === iti.OP_id)) {
          oldItineraires.splice(index, 1);
        }
      });

      dispatch(updateItinerairesImpactes(oldItineraires));
    }
    if (element.label === FIELDS_LABEL.BAPV_direction && map.mode === MAP_MODES.creation) {
      const oldBranches = [];
      const branchesSize = map.popupContent.branches.length;
      let lastBranche = {};
      map.popupContent.branches.map((branche, index) => {
        if (branche.L_code !== '' && index !== branchesSize - 1) {
          oldBranches.push(branche);
        }
        return oldBranches;
      });
      if (JSON.parse(value).key === 'F' && map.popupContent.branches[branchesSize - 1].L_code !== '') {
        lastBranche = {
          ...map.popupContent.branches[branchesSize - 1],
          pk_sncf: map.selectedSourceObject.pk_sncf_fin,
        };
      } else if (JSON.parse(value).key === 'D' && map.popupContent.branches[branchesSize - 1].L_code !== '') {
        lastBranche = {
          ...map.popupContent.branches[branchesSize - 1],
          pk_sncf: map.selectedSourceObject.pk_sncf_debut,
        };
      } else {
        lastBranche = { ...map.popupContent.branches[branchesSize - 1] };
      }
      oldBranches.push(lastBranche);
      dispatch(updatePopupContent(
        { ...map.popupContent, branches: oldBranches },
        map.selectedObjectLayer,
      ));
    }
    if (element.label === FIELDS_LABEL.BAPV_codeBranche && map.mode === MAP_MODES.creation) {
      onChange(value, path, true);
      const oldBranches = [];
      const branchesSize = map.popupContent.branches.length;
      let lastBranche = {};
      map.popupContent.branches.map((branche, index) => {
        if (branche.L_code !== '' && index !== branchesSize - 1) {
          oldBranches.push(branche);
        }
        return oldBranches;
      });
      if (JSON.parse(value).key === '02') {
        lastBranche = {
          ...map.popupContent.branches[branchesSize - 1],
          BAPV_pointObservation: true,
        };
      } else {
        lastBranche = {
          ...map.popupContent.branches[branchesSize - 1],
          BAPV_pointObservation: false,
        };
      }
      oldBranches.push(lastBranche);
      dispatch(updatePopupContent(
        { ...map.popupContent, branches: oldBranches },
        map.selectedObjectLayer,
      ));
    }
    if (element.label !== FIELDS_LABEL.perimetres_associes && element.label !== FIELDS_LABEL.perimetres_responsables && element.label !== FIELDS_LABEL.tables_associees
      && element.label !== FIELDS_LABEL.tables_responsables && element.label !== FIELDS_LABEL.itineraires_impactes && element.label !== FIELDS_LABEL.RA_libelle_poste_metier) onChange(value, path, getKey(element.label));
  };

  const onDeleteProp = (path) => {
    if (path[0] === DETAILS_TABLE_FIELDS.itineraires_impactes) {
      const newItineraires = [];
      map.itinerairesImpactes.map((iti, index) => {
        if (index !== path[1]) {
          newItineraires.push(iti);
        }
      });
      dispatch(updateItinerairesImpactes(newItineraires));
    }
    if (path[0] === DETAILS_TABLE_FIELDS.perimetres_associes) {
      const newPerimetres = [];
      map.perimetresAssocies.map((perimetre, index) => {
        if (index !== path[1]) {
          newPerimetres.push(perimetre);
        }
      });
      dispatch(updatePerimetresAssocies(newPerimetres));
    }
    if (path[0] === DETAILS_TABLE_FIELDS.perimetres_responsables) {
      const newPerimetres = [];
      map.perimetresResponsables.map((perimetre, index) => {
        if (index !== path[1]) {
          newPerimetres.push(perimetre);
        }
      });
      dispatch(updatePerimetresResponsables(newPerimetres));
    }
    dispatch(deletePopupContentProperty(path));
  };

  const renderInput = (element, path, style = undefined) => {
    const value = get(map.popupContent, path);
    if (element.values) {
      let selectedValue;
      if (value === '' || value === undefined) {
        [selectedValue] = element.values;
        onSelect(JSON.stringify(selectedValue), element, path);
      } else if (element.values.find((el) => el.key === value)) {
        selectedValue = element.values.find((el) => el.key === value);
      } else if (value.key) {
        selectedValue = value;
      }
      return (
        <div className="cell-inner small-cell-sidebar">
          <SelectSNCF
            id={path[0] + nextId()}
            options={element.values}
            selectedValue={selectedValue}
            onChange={(e) => onSelect(e.target.value, element, path)}
            labelKey="label"
            selectStyle="cell-inner small-cell-sidebar select-position select-sidebar"
            mode={map.mode}
          />
        </div>
      );
    }
    if (element.key === DETAILS_TABLE_FIELDS.RA_libelle_poste) {
      const posteId = get(map.popupContent, [DETAILS_TABLE_FIELDS.OP_id_poste]);
      let selectedValue;
      if (value === undefined || value === '') {
        [selectedValue] = map.postes;
        onSelect(JSON.stringify(selectedValue), element, path);
      } else if (map.postes.find((el) => el.RA_libelle === value && el.OP_id === posteId)) {
        selectedValue = map.postes.find((el) => el.OP_id === posteId);
      }
      return (
        <div className="cell-inner small-cell-sidebar">
          <SelectSNCF
            id={path[0] + nextId()}
            options={map.postes}
            selectedValue={selectedValue}
            onChange={(e) => onSelect(e.target.value, element, path)}
            labelKey="RA_libelle"
            selectStyle="cell-inner small-cell-sidebar select-position select-sidebar"
            mode={map.mode}
          />
        </div>
      );
    }
    if ((element.key === DETAILS_TABLE_FIELDS.perimetres_associes
       || element.key === DETAILS_TABLE_FIELDS.perimetres_responsables) && map.postesMetier) {
      const selectedValue = [];
      if (element.key === DETAILS_TABLE_FIELDS.perimetres_associes) {
        map.perimetresAssocies.forEach((perimetre) => {
          if (perimetre.OP_id_poste_metier !== '' && selectedValue.filter((perim) => perim.OP_id_poste_metier === perimetre.OP_id_poste_metier).length === 0) {
            selectedValue.push(map.postesMetier.filter((poste) => poste.OP_id === perimetre.OP_id_poste_metier));
          }
        });
      } else {
        map.perimetresResponsables.forEach((perimetre) => {
          if (perimetre.OP_id_poste_metier !== '' && selectedValue.filter((perim) => perim.OP_id_poste_metier === perimetre.OP_id_poste_metier).length === 0) {
            selectedValue.push(map.postesMetier.filter((poste) => poste.OP_id === perimetre.OP_id_poste_metier));
          }
        });
      }

      return (
        <div className="cell-inner small-cell-sidebar">
          <SelectSNCF
            id={path[0] + nextId()}
            options={map.postesMetier}
            selectedValue={selectedValue}
            onChange={(selectedOptions) => { onSelect(selectedOptions, element, path); }}
            labelKey="RA_libelle"
            selectStyle="cell-inner small-cell-sidebar select-position select-sidebar sr-only"
            mode={map.mode}
            multiple
            multiSelectTitle={t('Map.popupDetails.allPostes')}
          />
        </div>
      );
    }
    if (element.key === DETAILS_TABLE_FIELDS.tables_associees || element.key === DETAILS_TABLE_FIELDS.tables_responsables) {
      const tables = element.key === DETAILS_TABLE_FIELDS.tables_associees ? map.filteredTablesAssociees : map.filteredTablesResponsables;
      const selectedValue = [];
      const perimetres = element.key === DETAILS_TABLE_FIELDS.tables_associees ? map.perimetresAssocies : map.perimetresResponsables;

      if (perimetres.length !== 0) {
        perimetres.forEach((perimetre) => {
          if (perimetre.table !== '' && perimetre.table !== undefined && selectedValue.filter((table) => table === perimetre.table).length === 0) {
            selectedValue.push(perimetre.table);
          }
        });
      }
      return (
        <div className="cell-inner small-cell-sidebar">
          <SelectSNCF
            id={path[0] + nextId()}
            options={tables}
            selectedValue={selectedValue}
            onChange={(selectedOptions) => { onSelect(selectedOptions, element, path); }}
            selectStyle="cell-inner small-cell-sidebar select-position select-sidebar sr-only"
            mode={map.mode}
            multiple
            multiSelectTitle={element.key === DETAILS_TABLE_FIELDS.tables_associees ? t('Map.popupDetails.allTablesAssociees') : t('Map.popupDetails.allTablesResponsables')}
          />
        </div>
      );
    }
    if (element.key === DETAILS_TABLE_FIELDS.itineraires_impactes && map.itineraires) {
      const selectedValue = [];
      map.itinerairesImpactes.forEach((itineraire) => {
        if (itineraire.OP_id !== '' && map.popupContent.itineraires_impactes.filter((iti) => iti.OP_id === itineraire.OP_id).length === 0) {
          selectedValue.push(map.itineraires.filter((iti) => iti.OP_id === itineraire.OP_id));
        }
      });

      return (
        <div className="cell-inner small-cell-sidebar">
          <SelectSNCF
            id={path[0] + nextId()}
            options={map.itineraires}
            selectedValue={selectedValue}
            onChange={(selectedOptions) => { onSelect(selectedOptions, element, path); }}
            labelKey="RA_libelle"
            selectStyle="cell-inner small-cell-sidebar select-position select-sidebar sr-only"
            mode={map.mode}
            multiple
            multiSelectTitle={t('Map.popupDetails.allItineraires')}
          />
        </div>
      );
    }
    return (
      <div className="cell-inner small-cell-sidebar">
        <input
          type="text"
          onChange={(e) => onChange(e.target.value, path)}
          className="text-monospace form-control form-control-sm"
          style={style}
          id={path[0] + nextId()}
          value={typeof value === 'number' ? value.toString() : value || ''}
        />
      </div>
    );
  };

  const getHeader = (field, index, label, path) => {
    switch (field) {
      case DETAILS_TABLE_FIELDS.extremites:
        return (
          <strong className="cell-inner small-cell-sidebar">
            {(get(map.popupContent, [field, index, DETAILS_TABLE_FIELDS.RA_libelle]) !== undefined
              && get(map.popupContent, [field, index, DETAILS_TABLE_FIELDS.RA_libelle]) !== '')
              ? get(map.popupContent, [field, index, DETAILS_TABLE_FIELDS.RA_libelle]) : `${label.slice(0, -1)}`}
            {(map.mode !== MAP_MODES.creation && get(map.popupContent, [field, index, DETAILS_TABLE_FIELDS.V_nom]) !== '')
            && ` Voie ${get(map.popupContent, [field, index, DETAILS_TABLE_FIELDS.V_nom])} 
            ${get(map.popupContent, [field, index, DETAILS_TABLE_FIELDS.pk_sncf])}`}
          </strong>
        );
      case DETAILS_TABLE_FIELDS.sels:
        return (
          <strong className="cell-inner small-cell-sidebar">
            {get(map.popupContent, [field, index, DETAILS_TABLE_FIELDS.RA_libelle]) === ''
              ? t('Map.popupDetails.newCatenaire')
              : get(map.popupContent, [field, index, DETAILS_TABLE_FIELDS.RA_libelle])}
          </strong>
        );
      case DETAILS_TABLE_FIELDS.groupements_catenaires:
        return (
          <strong className="cell-inner small-cell-sidebar">
            {get(map.popupContent, [field, index, DETAILS_TABLE_FIELDS.RA_libelle]) === ''
              ? t('Map.popupDetails.newGrpCatenaire')
              : get(map.popupContent, [field, index, DETAILS_TABLE_FIELDS.RA_libelle])}
          </strong>
        );
      default:
        return (
          <strong className={`${field === DETAILS_TABLE_FIELDS.adv_positionnes ? 'ml-2' : ''} cell-inner small-cell-sidebar`}>
            {label.slice(0, -1)}
            {' '}
            {get(map.popupContent, [field, index, DETAILS_TABLE_FIELDS.RA_libelle])}
          </strong>
        );
    }
  };

  const renderArrayElements = (element, field, index, label, values, path) => {
    const deleteMessage = t('Map.popupDetails.deleteButton');
    const trStyle = field === DETAILS_TABLE_FIELDS.itineraires
      || field === DETAILS_TABLE_FIELDS.perimetres_associes
      || field === DETAILS_TABLE_FIELDS.perimetres_responsables ? '' : 'grey-background';

    const displayDeleteButtonGrp = (map.mode === MAP_MODES.modification || map.mode === MAP_MODES.creation)
                                && map.selectedObjectLayer.key === MIDI_OBJECTS_KEYS.groupementSel
                                && totalCount(DETAILS_TABLE_FIELDS.sels, DETAILS_TABLE_FIELDS.groupements_catenaires) > 1;

    return (
      <>
        {field === DETAILS_TABLE_FIELDS.adv_positionnes && index === 0
              && (
                <>
                  <tr style={{ height: '3vh' }}><div>{}</div></tr>
                  <tr style={{ backgroundColor: '#C0C0C0' }}>
                    <th colSpan={map.mode === MAP_MODES.display ? 3 : 1}>
                      <strong className="cell-inner small-cell-sidebar">
                        {t('Map.popupDetails.adv')}
                      </strong>
                    </th>
                    {map.mode !== MAP_MODES.display && <th><div>{}</div></th>}
                  </tr>
                </>
              )}
        <tr
          className={`${map.selectedProperty && isEqual(map.selectedProperty.path, path) ? 'selected-row' : trStyle}`}
          onClick={map.mode !== MAP_MODES.display && element.onClick ? () => element.onClick({ path }) : () => {}}
        >
          <th colSpan={map.mode === MAP_MODES.display
            && field !== DETAILS_TABLE_FIELDS.perimetres_associes
              && field !== DETAILS_TABLE_FIELDS.perimetres_responsables ? 3 : 1}
          >
            {getHeader(field, index, label, path)}
            {map.mode === MAP_MODES.display && field === DETAILS_TABLE_FIELDS.cles_protections
                && <strong className="cell-inner small-cell-sidebar">{get(map.popupContent, path)}</strong>}
          </th>
          {displayDeleteButtonGrp
          && (
          <th className="d-flex justify-content-end" style={{ fontWeight: 'normal' }}>
            <button
              className="btn btn-sm btn-transparent btn-sm-sidebar ml-2"
              type="button"
              title={deleteMessage}
              onClick={() => onDeleteProp(path)}
            >
              <i className="icons-close" aria-hidden="true" style={{ color: '#212529', fontSize: '.4rem' }} />
            </button>
          </th>
          )}
          {map.mode !== MAP_MODES.display && values.length > 1
          && (
            <th className="d-flex justify-content-end" style={{ fontWeight: 'normal' }}>
              {field === DETAILS_TABLE_FIELDS.cles_protections
                && renderInput(element, path, { backgroundColor: 'white' })}
              {field === DETAILS_TABLE_FIELDS.itineraires_impactes
                && get(map.popupContent, [...path, DETAILS_TABLE_FIELDS.RA_libelle]) === ''
                 && renderInput(element, path)}
              {(field === DETAILS_TABLE_FIELDS.perimetres_associes
              || field === DETAILS_TABLE_FIELDS.perimetres_responsables) && (
                <div className="cell-inner small-cell-sidebar text-monospace">
                  {(get(map.popupContent, [...path, DETAILS_TABLE_FIELDS.OP_id_poste_metier]) === ''
                    ? renderInput(element, path) : get(map.popupContent, [field, index, DETAILS_TABLE_FIELDS.RA_libelle_poste_metier]))}

                  {get(map.popupContent, [...path, DETAILS_TABLE_FIELDS.OP_id_poste_metier]) !== ''
                  && get(map.popupContent,
                    [field, index, DETAILS_TABLE_FIELDS.table])
                    && `/ 
                    ${get(map.popupContent, [field, index, DETAILS_TABLE_FIELDS.table])}`}
                </div>
              )}
              {!displayDeleteButtonGrp && (
              <button
                className="btn btn-sm btn-transparent btn-sm-sidebar ml-2"
                type="button"
                title={deleteMessage}
                onClick={() => onDeleteProp(path)}
              >
                <i className="icons-close" aria-hidden="true" style={{ color: '#212529', fontSize: '.4rem' }} />
              </button>
              )}
            </th>
          )}
          {map.mode !== MAP_MODES.display && values.length === 1
          && (
            <th className="d-flex justify-content-end" style={{ fontWeight: 'normal' }}>
              {field === DETAILS_TABLE_FIELDS.cles_protections
                && renderInput(element, path, { backgroundColor: 'white' })}
              {field === DETAILS_TABLE_FIELDS.itineraires_impactes
                && get(map.popupContent, [...path, DETAILS_TABLE_FIELDS.RA_libelle]) === '' && renderInput(element, path)}

              {(field === DETAILS_TABLE_FIELDS.perimetres_associes
              || field === DETAILS_TABLE_FIELDS.perimetres_responsables) && (
                <div className="cell-inner small-cell-sidebar text-monospace">
                  {(get(map.popupContent, [...path, DETAILS_TABLE_FIELDS.OP_id_poste_metier]) === ''
                    ? renderInput(element, path) : get(map.popupContent, [field, index, DETAILS_TABLE_FIELDS.RA_libelle_poste_metier]))}
                  {get(map.popupContent, [...path, DETAILS_TABLE_FIELDS.OP_id_poste_metier]) !== '' && get(map.popupContent,
                    [field, index, DETAILS_TABLE_FIELDS.table]) && `/ ${get(map.popupContent, [field, index, DETAILS_TABLE_FIELDS.table])}`}
                </div>
              )}
              {(field === DETAILS_TABLE_FIELDS.cles_protections || field === DETAILS_TABLE_FIELDS.itineraires_impactes
                || field === DETAILS_TABLE_FIELDS.perimetres_associes || field === DETAILS_TABLE_FIELDS.perimetres_responsables)
                && !displayDeleteButtonGrp
              && (
              <button
                className="btn btn-sm btn-transparent btn-sm-sidebar ml-2"
                type="button"
                title={deleteMessage}
                onClick={() => onDeleteProp(path)}
              >
                <i className="icons-close" aria-hidden="true" style={{ color: '#212529', fontSize: '.4rem' }} />
              </button>
              )}
            </th>
          )}
          {map.mode === MAP_MODES.display && (field === DETAILS_TABLE_FIELDS.perimetres_associes
          || field === DETAILS_TABLE_FIELDS.perimetres_responsables)
          && get(map.popupContent, [...path, DETAILS_TABLE_FIELDS.OP_id_poste_metier]) !== ''
          && (
            <th style={{ fontWeight: 'normal' }}>
              <div className="cell-inner small-cell-sidebar text-monospace">
                {get(map.popupContent, [...path, DETAILS_TABLE_FIELDS.RA_libelle_poste_metier])}
                {get(map.popupContent,
                  [field, index, DETAILS_TABLE_FIELDS.table])
                && ` / ${get(map.popupContent, [field, index, DETAILS_TABLE_FIELDS.table])}`}
              </div>
            </th>
          )}
        </tr>
      </>
    );
  };

  const getExtremitesSens = (element) => {
    switch (element.fields[3].key) {
      case DETAILS_TABLE_FIELDS.RLE_sens:
        return DETAILS_TABLE_FIELDS.RLE_sens;
      case DETAILS_TABLE_FIELDS.RLJDZ_sens:
        return DETAILS_TABLE_FIELDS.RLJDZ_sens;
      default:
        return DETAILS_TABLE_FIELDS.L_ordre;
    }
  };

  const getContent = (element, field, index, label, values, path) => {
    const getButtonStyle = (i) => (get(map.popupContent, [field, index, DETAILS_TABLE_FIELDS.branches])[i].BAPV_codeBranche === '' ? '' : 'btn-sm');
    switch (field) {
      case DETAILS_TABLE_FIELDS.extremites:
        return (
          <>
            <tr>
              <td style={{ width: '12.75rem' }}>
                <div className="cell-inner small-cell-sidebar text-monospace">
                  <strong>
                    {get(map.popupContent, [field, index, DETAILS_TABLE_FIELDS.L_code])}
                    {' '}
                    Voie
                    {' '}
                    {get(map.popupContent, [field, index, DETAILS_TABLE_FIELDS.V_nom])}
                    {' '}
                  </strong>
                </div>
              </td>
              <td style={{ width: '10rem' }}>
                <div className="cell-inner small-cell-sidebar text-monospace">
                  <strong className="mr-2">{t('Map.popupDetails.pk')}</strong>
                  {map.mode !== MAP_MODES.display ? renderInput(element.fields[2], [field, index, DETAILS_TABLE_FIELDS.pk_sncf])
                    : get(map.popupContent, [field, index, DETAILS_TABLE_FIELDS.pk_sncf])}
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <div className="cell-inner small-cell-sidebar text-monospace">
                  <strong>
                    {element.fields[3].label}
                  </strong>
                </div>
              </td>
              <td style={{ width: '10rem' }}>
                {map.mode !== MAP_MODES.display ? <div className="ml-4">{renderInput(element.fields[3], [field, index, getExtremitesSens(element)])}</div>
                  : (
                    <div className="cell-inner small-cell-sidebar text-monospace">
                      {get(map.popupContent, [field, index, getExtremitesSens(element)])}
                    </div>
                  )}
              </td>
            </tr>
          </>
        );
      case DETAILS_TABLE_FIELDS.adv_positionnes:
        return (
          <>
            <tr>
              <td style={{ width: '12.75rem' }}>
                <div className="cell-inner small-cell-sidebar text-monospace ml-3">
                  <strong>
                    {t('Map.popupDetails.branch')}
                  </strong>
                </div>
              </td>
              <td style={{ width: '10rem' }}>
                <div className="cell-inner small-cell-sidebar text-monospace d-flex">
                  <button
                    className={`${map.selectedProperty && isEqual(map.selectedProperty.path, [field, index, DETAILS_TABLE_FIELDS.branches, 0])
                      ? `selected-row mx-2 btn ${getButtonStyle(0)}` : `mx-2 btn ${getButtonStyle(0)}`}`}
                    onClick={map.mode !== MAP_MODES.display && element.fields[1].onClick
                      ? () => element.fields[1].onClick({ path: [field, index, DETAILS_TABLE_FIELDS.branches, 0] }) : () => {}}
                    type="button"
                  >
                    {get(map.popupContent, [field, index, DETAILS_TABLE_FIELDS.branches])[0].BAPV_codeBranche}
                  </button>
                  <button
                    className={`${map.selectedProperty && isEqual(map.selectedProperty.path, [field, index, DETAILS_TABLE_FIELDS.branches, 1])
                      ? `selected-row mx-2 btn ${getButtonStyle(1)}` : `mx-2 btn ${getButtonStyle(1)}`}`}
                    onClick={map.mode !== MAP_MODES.display && element.fields[1].onClick
                      ? () => element.fields[1].onClick({ path: [field, index, DETAILS_TABLE_FIELDS.branches, 1] }) : () => {}}
                    type="button"
                  >
                    {get(map.popupContent, [field, index, DETAILS_TABLE_FIELDS.branches])[1].BAPV_codeBranche}
                  </button>
                </div>
              </td>
            </tr>
            <div style={{ height: '1vh' }}>{}</div>
          </>
        );
      case DETAILS_TABLE_FIELDS.branches:
        return map.mode === MAP_MODES.creation
          ? (
            <>
              <tr>
                <td style={{ width: '12.75rem' }}>
                  <div className="cell-inner small-cell-sidebar text-monospace">
                    <strong>
                      {get(map.popupContent, [field, index, DETAILS_TABLE_FIELDS.L_code])}
                      {' '}
                      Voie
                      {' '}
                      {get(map.popupContent, [field, index, DETAILS_TABLE_FIELDS.V_nom])}
                      {' '}
                    </strong>
                  </div>
                </td>
                <td style={{ width: '10rem' }}>
                  <div className="cell-inner small-cell-sidebar text-monospace">
                    <strong className="mr-2">{t('Map.popupDetails.pk')}</strong>
                    {map.mode !== MAP_MODES.display ? renderInput(element.fields[2], [field, index, DETAILS_TABLE_FIELDS.pk_sncf])
                      : get(map.popupContent, [field, index, DETAILS_TABLE_FIELDS.pk_sncf])}
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div className="cell-inner small-cell-sidebar text-monospace">
                    <strong>
                      {element.fields[3].label}
                    </strong>
                  </div>
                </td>
                <td style={{ width: '10rem' }}>
                  <div className="ml-4">{renderInput(element.fields[3], [field, index, DETAILS_TABLE_FIELDS.BAPV_direction])}</div>
                </td>
              </tr>
              <tr>
                <td>
                  <div className="cell-inner small-cell-sidebar text-monospace">
                    <strong>
                      {element.fields[4].label}
                    </strong>
                  </div>
                </td>
                <td style={{ width: '10rem' }}>
                  <div className="ml-4">{renderInput(element.fields[4], [field, index, DETAILS_TABLE_FIELDS.BAPV_codeBranche])}</div>
                </td>
              </tr>
            </>
          ) : (
            renderArrayElements(element, field, index, label, values, path)
          );
      default:
        return (
          renderArrayElements(element, field, index, label, values, path)
        );
    }
  };

  const getDetails = (element, tempElement, isEditable) => {
    const booleanValue = map.popupContent[tempElement] === true ? 'O' : 'N';
    switch (tempElement) {
      case DETAILS_TABLE_FIELDS.RA_libelle_signal_origine:
        return (
          <td style={{ width: '15rem' }}>
            { map.mode !== MAP_MODES.display && isEditable
              ? renderInput(element, [tempElement])
              : (
                <div className="cell-inner small-cell-sidebar text-monospace">
                  {map.popupContent[tempElement]}
                </div>
              )}
            <div className="cell-inner small-cell-sidebar text-monospace">
              {map.popupContent.L_code_signalOrigine}
              {' '}
              {map.popupContent[`${tempElement}`] !== '' && 'Voie'}
              {' '}
              {map.popupContent.V_nom_signalOrigine}
              {' '}
              {map.popupContent.pk_sncf_signalOrigine}
            </div>
          </td>
        );
      case DETAILS_TABLE_FIELDS.RA_libelle_signal_destination:
        return (
          <td style={{ width: '15rem' }}>
            { map.mode !== MAP_MODES.display && isEditable
              ? renderInput(element, [tempElement])
              : (
                <div className="cell-inner small-cell-sidebar text-monospace">
                  {map.popupContent[tempElement]}
                </div>
              )}
            <div className="cell-inner small-cell-sidebar text-monospace">
              {map.popupContent.L_code_signalDestination}
              {' '}
              {map.popupContent[`${tempElement}`] !== '' && 'Voie'}
              {' '}
              {map.popupContent.V_nom_signalDestination}
              {' '}
              {map.popupContent.pk_sncf_signalDestination}
            </div>
          </td>
        );
      case DETAILS_TABLE_FIELDS.RA_libelle_poste:
        return (
          <td style={{ width: '15rem' }}>
            { map.mode !== MAP_MODES.display && map.postes.length !== 0
              ? renderInput(element, [tempElement])
              : (
                <div className="cell-inner small-cell-sidebar text-monospace">
                  {map.popupContent[tempElement]}
                </div>
              )}
          </td>
        );
      case DETAILS_TABLE_FIELDS.RA_libelle_poste_metier:
        return (
          <td style={{ width: '15rem' }}>
            { map.mode !== MAP_MODES.display && map.postesMetier.length !== 0
              ? renderInput(element, [tempElement])
              : (
                <div className="cell-inner small-cell-sidebar text-monospace">
                  {map.popupContent[tempElement]}
                </div>
              )}
          </td>
        );
      default:
        return (
          <td style={{ width: '10rem' }}>
            { map.mode !== MAP_MODES.display && isEditable
              ? <div className={map.sidebarTab === '#geom' ? 'ml-4 ' : ''}>{renderInput(element, [tempElement])}</div>
              : (
                <div className="cell-inner small-cell-sidebar text-monospace">
                  {typeof map.popupContent[tempElement] === 'boolean' ? booleanValue : map.popupContent[tempElement]}
                </div>
              )}
          </td>
        );
    }
  };

  const renderBody = (fields) => (
    <table className="table table-hover mt-2 mb-0 w-100 detailed-table" style={{ fontSize: '.9rem' }}>
      <tbody>
        {map.selectedObjectLayer.key === MIDI_OBJECTS_KEYS.itineraire && map.sidebarTab === '#geom'
            && (
            <tr style={{ backgroundColor: '#C0C0C0' }}>
              <th scope="row" colSpan={map.mode === MAP_MODES.display ? 3 : 1}>
                <strong className="cell-inner small-cell-sidebar">
                  {t('Map.popupDetails.signaux')}
                </strong>
              </th>
              {map.mode !== MAP_MODES.display && <th><div>{}</div></th>}
            </tr>
            )}
        {fields.map((element, index) => {
          const tempElement = element.key || element;
          const isEditable = element.key ? element.isEditable : true;
          const label = element.isHidden ? '' : element.label || element.slice(0, 20);
          const values = map.popupContent[tempElement];

          if (Array.isArray(values)) {
            return (
              <>
                {values.map((val, i) => (
                  <>
                    {renderArrayElements(element, tempElement, i, label, values, [tempElement, i])}
                    {tempElement !== DETAILS_TABLE_FIELDS.cles_protections && element.fields.map((field) => {
                      const path = [tempElement, i, field.key];
                      const value = get(map.popupContent, path);
                      if (Array.isArray(value)) {
                        return value.map((subVal, j) => {
                          const subPath = [tempElement, i, field.key, j];
                          return (
                            <>
                              {field.key !== DETAILS_TABLE_FIELDS.branches && getContent(field, field.key, j, field.label, value, subPath)}
                            </>
                          );
                        });
                      }
                    })}
                    {(tempElement === DETAILS_TABLE_FIELDS.extremites || tempElement === DETAILS_TABLE_FIELDS.adv_positionnes
                      || (tempElement === DETAILS_TABLE_FIELDS.branches && map.selectedObjectLayer.key === MIDI_OBJECTS_KEYS.adv))
                            && getContent(element, tempElement, i, label, values, [tempElement, i])}
                  </>
                ))}
              </>
            );
          }
          return (
            <>
              {!element.isHidden && tempElement === DETAILS_TABLE_FIELDS.L_code && map.selectedObjectLayer.key !== MIDI_OBJECTS_KEYS.tivSch && (
              <>
                <tr
                  className={`${map.selectedProperty && isEqual(map.selectedProperty.path, ['L_code']) ? 'selected-row' : ''}`}
                  onClick={map.mode !== MAP_MODES.display && element.onClick ? () => element.onClick({ path: ['L_code'] }) : () => {}}
                >
                  <td style={{ width: '12.75rem' }}>
                    <div className="cell-inner small-cell-sidebar text-monospace">
                      <strong>
                        {map.popupContent.L_code}
                        {' '}
                        Voie
                        {' '}
                        {map.popupContent.V_nom}
                        {' '}
                      </strong>
                    </div>
                  </td>
                  <td style={{ width: '10rem' }}>
                    <div className="cell-inner small-cell-sidebar text-monospace">
                      <strong className="mr-2">{t('Map.popupDetails.pk')}</strong>
                      {map.mode !== MAP_MODES.display ? renderInput(DETAILS_TABLE_FIELDS.pk_sncf, DETAILS_TABLE_FIELDS.pk_sncf)
                        : map.popupContent.pk_sncf}
                    </div>
                  </td>
                </tr>
              </>
              )}
              {!element.isHidden && tempElement !== DETAILS_TABLE_FIELDS.pk_sncf
                      && tempElement !== DETAILS_TABLE_FIELDS.L_code
                      && tempElement !== DETAILS_TABLE_FIELDS.V_nom
                      && tempElement !== DETAILS_TABLE_FIELDS.RA_libelle_gare
                      && map.selectedObjectLayer.key !== MIDI_OBJECTS_KEYS.tivSch
                      && (
                      <tr
                        key={tempElement}
                        className={`${map.selectedProperty && map.selectedProperty.key === element.key ? 'selected-row' : ''}`}
                        onClick={map.mode !== MAP_MODES.display ? element.onClick : () => {}}
                      >
                        <td>
                          <div
                            className={`cell-inner small-cell-sidebar text-monospace font-weight-bold 
                              ${map.selectedObjectLayer.key === MIDI_OBJECTS_KEYS.itineraire ? 'ml-2' : ''} `}
                          >
                            {label}
                          </div>
                        </td>
                        {getDetails(element, tempElement, isEditable)}
                      </tr>
                      )}
              {!element.isHidden && map.selectedObjectLayer.key === MIDI_OBJECTS_KEYS.tivSch
                      && (
                      <tr
                        key={tempElement}
                        className={`${map.selectedProperty && map.selectedProperty.key === element.key ? 'selected-row' : ''}`}
                        onClick={map.mode !== MAP_MODES.display ? element.onClick : () => {}}
                      >
                        <td>
                          <div
                            className={`cell-inner small-cell-sidebar text-monospace font-weight-bold 
                              ${map.selectedObjectLayer.key === MIDI_OBJECTS_KEYS.itineraire ? 'ml-2' : ''} `}
                          >
                            {label}
                          </div>
                        </td>
                        {getDetails(element, tempElement, isEditable)}
                      </tr>
                      )}
              {!element.isHidden && tempElement === DETAILS_TABLE_FIELDS.RA_libelle_gare
                && (Object.keys(map.popupContent).includes(DETAILS_TABLE_FIELDS.OP_id_gare_G_postesId)
                || Object.keys(map.popupContent).includes(DETAILS_TABLE_FIELDS.OP_id_nyx_gare_G_postesId))
                      && (
                      <tr
                        key={tempElement}
                        className={`${map.selectedProperty && map.selectedProperty.key === element.key ? 'selected-row' : ''}`}
                        onClick={map.mode !== MAP_MODES.display ? element.onClick : () => {}}
                      >
                        <td>
                          <div
                            className={`cell-inner small-cell-sidebar text-monospace font-weight-bold 
                              ${map.selectedObjectLayer.key === MIDI_OBJECTS_KEYS.itineraire ? 'ml-2' : ''} `}
                          >
                            {label}
                          </div>
                        </td>
                        {getDetails(element, tempElement, isEditable)}
                      </tr>
                      )}
            </>
          );
        })}
      </tbody>
    </table>
  );

  const creationGeomFields = { ...GEOM_FIELDS, ...GEOM_CREATION };
  const creationAttribFields = { ...ATTRIB_FIELDS, ...ATTRIB_CREATION };

  const allGeomFields = map.mode === MAP_MODES.creation ? creationGeomFields : GEOM_FIELDS;
  const allAttribFields = map.mode === MAP_MODES.creation ? creationAttribFields : ATTRIB_FIELDS;

  const fields = Object.keys(map.popupContent);
  let geomFields = [];
  let attribFields = [];
  if (map.selectedObjectLayer) {
    if (map.selectedObjectLayer.sourceTable === MIDI_OBJECTS_LAYERS.tivSch) {
      attribFields = fields;
    }
    if (allGeomFields[map.selectedObjectLayer.key]) {
      geomFields = allGeomFields[map.selectedObjectLayer.key].filter(
        (el) => fields.includes(el.key),
      );
    }
    if (allAttribFields[map.selectedObjectLayer.key]) {
      attribFields = allAttribFields[map.selectedObjectLayer.key].filter(
        (el) => fields.includes(el.key),
      );
    } else {
      const geomKeys = [];
      Object.keys(geomFields).forEach((field) => geomKeys.push(geomFields[field].key));
      attribFields = difference(fields, geomKeys);
    }
  }

  if (map.mode !== MAP_MODES.display) {
    geomFields = geomFields.filter((field) => !field.hideModification);
    attribFields = attribFields.filter((field) => !field.hideModification);
  }

  return (
    <>
      {geomFields.length !== 0 && map.sidebarTab === '#geom' && renderBody(geomFields)}
      {attribFields.length !== 0 && map.sidebarTab === '#attributs' && renderBody(attribFields)}
    </>
  );
};

export default Table;
